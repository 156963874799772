export const educationBase = [
    {
        id: 0,
        ref: null,

        playingBIG: false,
        playingSML: false,

        question: 'Как войти в систему?',

        textBIG: 
            <>
                <div>Для входа в систему мониторинга, необходимо перейти по ссылке <a className='emphasized' href='https://crm.controlgps.org' target='_blank' rel="noreferrer">https://crm.controlgps.org</a>.</div>
                <div>В открывшемся окне введите вашу почту в поле <span className='emphasized'>"Логин"</span> и ваш пароль в поле <span className='emphasized'>"Пароль"</span>.</div>
                <div>После нажатия на кнопку <span className='emphasized'>"Войти"</span>, Вы окажетесь в вашем автопарке.</div>
            </>,

        textSML: 
            <>
                <div>Для входа в мобильное приложение, Вам сначала необходимо скачать его из магазина приложений:</div>
                <div style={{'wordBreak': 'break-all'}}>Android - <a className='emphasized' href='https://play.google.com/store/apps/details?id=org.controlgps&pli=1' target='_blank' rel="noreferrer">https://play.google.com/store/apps/details?id=org.controlgps&pli=1</a></div>
                <div style={{'wordBreak': 'break-all'}}>IOS - <a className='emphasized' href='https://apps.apple.com/ru/app/controlgps/id6444350023?l=ru' target='_blank' rel="noreferrer">https://apps.apple.com/ru/app/controlgps/id6444350023?l=ru</a></div>
                <div>После запуска приложения, введите вашу почту в поле <span className='emphasized'>"Логин"</span> и ваш пароль в поле <span className='emphasized'>"Пароль"</span>.</div>
            </>,

        urlBIG: 'https://youtu.be/r9pfdEzCDpM?feature=shared',
        urlSML: 'https://youtu.be/ZwXLwcL6qKo?feature=shared',
    },
    {
        id: 1,
        ref: null,
        
        playingBIG: false,
        playingSML: false,

        question: 'Что делать, если система пишет "Неверно введен логин и/или пароль"?',

        textBIG: 
            <>
                <div>Если у Вас остался доступ к другим пользователям, Вы можете сбросить пароль.</div>
                <div>Для этого необходимо перейти на страницу <span className='emphasized'>"Настройки"</span>, там нужно открыть вкладку <span className='emphasized'>"Пользователи"</span> и из списка пользователей выбрать того, с чьей учетной записи не получается зайти.</div>
                <div>В открывшемся профиле Вы можете ввести новый пароль и сохранить изменения.</div>
                <div>После этого Вы сможете авторизоваться по новому паролю.</div>
                <div className='MT-8'>В случае если Вы потеряли доступ к единственному пользователю системы, Вам необходимо обратиться в нашу службу поддержки : <a className='emphasized' href='https://t.me/controlgps' target='_blank' rel="noreferrer">@ControlGPS_support_bot</a></div>
            </>,

        textSML: 
            <>
                <div>Необходимо обратиться в нашу службу поддержки : <a className='emphasized' href='https://t.me/controlgps' target='_blank' rel="noreferrer">@ControlGPS_support_bot</a></div>
            </>,

        urlBIG: 'https://youtu.be/5GwkhAIxGcQ?feature=shared',
        urlSML: null,
    },
    {
        id: 2,
        ref: null,
        
        playingBIG: false,
        playingSML: false,

        question: 'Общий обзор системы',

        textBIG: 
            <>
                <div>Вам доступны 5 вкладок:</div>
                <div className='ML-16 MT-8'>{'1) '} <span className='emphasized'>Карта:</span> Вы можете следить за перемещениями ваших транспортных средств, отслеживать их маршруты, отправлять команды на глушение и отслеживать выезды за геозоны.</div>
                <div className='ML-16 MT-8'>{'2) '} <span className='emphasized'>Дашборд:</span> Здесь Вы можете посмотреть топ авто по пробегам за необходимый период, проверить оповещения по автомобилям, и отслеживать количество машин на линии в любой момент времени.</div>
                <div className='ML-16 MT-8'>{'3) '} <span className='emphasized'>Отчет:</span> В этой вкладке можно выбрать нужные автомобили и интересующие Вас показатели, по которым построится отчет за любой период времени. Также этот отчет можно экспортировать в Excel.</div>
                <div className='ML-16 MT-8'>{'4) '} <span className='emphasized'>Список авто:</span> в этой вкладке Вы можете настроить автомобили, добавленные в Ваш автопарк. Здесь Вы можете поменять госномер, марку и модель авто. Также Вы можете указать текущий пробег авто, от него будет отсчитываться дальнейший пробег по GPS трекеру. Здесь же можно сменить цвет иконки авто, для удобства работы с картой.</div>
                <div className='ML-16 MT-8'>{'5) '} <span className='emphasized'>Настройки:</span> В этой вкладке Вы можете настроить группы авто; создать новых пользователей, или отредактировать старых; настроить стартовое положение карты при входе в систему</div>
            </>,

        textSML: 
            <>
                <div>В мобильном приложении есть две основные страницы - <span className='emphasized'>карта</span> и <span className='emphasized'>список авто:</span></div>
                <div className='ML-16 MT-8'>{'1) '} Находясь на <span className='emphasized'>карте</span>, Вы можете кликнуть на интересующий автомобиль, после чего откроется его профиль. В профиле авто можно найти всю интересующую Вас информацию - текущую скорость, длительность смены, пробег авто. При клике на синюю иконку с геометкой, карта центрируется на авто. Здесь же Вы можете заглушить двигатель или посмотреть историю поездок Вашего авто.</div>
                <div className='ML-16 MT-8'>{'2) '} В <span className='emphasized'>списке авто</span> Вы можете удобно искать нужное транспортное средство в вашем автопарке. При клике на авто, Вы перемещаетесь на карту с его текущим местоположением</div>
            </>,

        urlBIG: 'https://youtu.be/j_2YJj_IYDg?feature=shared',
        urlSML: 'https://youtu.be/UjyRYhVA0b4?feature=shared',
    },
    {
        id: 3,
        ref: null,
        
        playingBIG: false,
        playingSML: false,

        question: 'Как заглушить/разглушить авто?',

        textBIG: 
            <>
                <div>Чтобы заглушить автомобиль, вам необходимо перейти на вкладку <span className='emphasized'>"Карта"</span> и найти нужное авто.</div>
                <div>После клика по иконке замка, команда отправляется на устройство.</div>
                <div>Выполнение команды занимает в среднем 5-10 минут, в это время кнопка глушения будет окрашена в желтый цвет.</div>
                <div>После успешного глушения, отображается красная иконка замка.</div>
                <div>Если кликнуть на красный замок, отправится команда на разглушение автомобиля.</div>
            </>,

        textSML: 
            <>
                <div>Откройте профиль авто, кликнув по нему на карте или в списке авто.</div>
                <div>После нажатия на кнопку <span className='emphasized'>"Заглушить двигатель"</span>, команда отправляется на устройство.</div>
                <div>Выполнение команды занимает в среднем 5-10 минут, в это время кнопка глушения будет недоступна.</div>
                <div>После успешного глушения двигателя, кнопка меняется на кнопку <span className='emphasized'>"Разглушить двигатель"</span>.</div>
            </>,

        urlBIG: 'https://youtu.be/lozBuFe_WTU?feature=shared',
        urlSML: 'https://youtu.be/f53FuWTnHN8?feature=shared',
    },
    {
        id: 4,
        ref: null,
        
        playingBIG: false,
        playingSML: false,

        question: 'Как посмотреть историю поездок?',

        textBIG: 
            <>
                <div>Чтобы посмотреть историю поездок по авто, Вам необходимо перейти на вкладку <span className='emphasized'>"Карта"</span> и нажать на три точки у интересующего Вас автомобиля.</div>
                <div>После нажатия по кнопке <span className='emphasized'>"История поездок"</span>, будет построен маршрут за текущий день.</div>
                <div>Вы можете изменить период выгрузки истории на любые интересующие Вас даты, кликнув по календарю с датами.</div>
            </>,

        textSML: 
            <>
                <div>Откройте профиль авто, кликнув по нему на <span className='emphasized'>карте</span> или в <span className='emphasized'>списке авто</span>.</div>
                <div>Нажав на кнопку <span className='emphasized'>"История поездок"</span>, Вы перейдете в режим просмотра истории перемещения Вашего авто.</div>
                <div>Вы можете выбрать произвольный период выгрузки истории, нажав по календарю с датой.</div>
            </>,

        urlBIG: 'https://youtu.be/emZxB9SsspQ?feature=shared',
        urlSML: 'https://youtu.be/hsL2FV95Cag?feature=shared',
    },
    {
        id: 5,
        ref: null,
        
        playingBIG: false,
        playingSML: false,

        question: 'Как добавить новых пользователей и ограничить им доступ к просмотру авто?',

        textBIG: 
            <>
                <div>Чтобы добавить нового пользователя, необходимо перейти на страницу <span className='emphasized'>"Настройки"</span> и открыть вкладку <span className='emphasized'>"Пользователи"</span>.</div>
                <div>Нажав на кнопку с плюсом в правом верхнем углу экрана, Вы сможете ввести данные нового пользователя.</div>
                <div>Если Вы выключите настройку <span className='emphasized'>"Доступ ко всем группам"</span>, то у Вас появится возможность открыть пользователю только определенные группы авто, благодаря чему можно скрыть от сотрудника ненужные ему автомобили.</div>
                <div className='MT-8'>При клике на пользователя в общем списке, Вы сможете настроить для каждого из них масштаб иконок авто на карте.</div>
                <div className='MT-8'>Для удобной работы с автопарком, Вы можете делить свои транспортные средства по группам авто. Для этого нужно перейти на страницу <span className='emphasized'>"Настройки"</span>, и открыть вкладку <span className='emphasized'>"Группы авто"</span>.</div>
                <div>Здесь Вы можете разделить все автомобили по группам, к которым можно дать доступ отдельным пользователям</div>
            </>,

        textSML: 
            <>
                <div>Недоступно для мобильной версии приложения.</div>
            </>,

        urlBIG: 'https://youtu.be/oRJ3Ytm9OqA?feature=shared',
        urlSML: null,
    },
    {
        id: 6,
        ref: null,
        
        playingBIG: false,
        playingSML: false,

        question: 'Как построить геозону?',

        textBIG: 
            <>
                <div>Чтобы построить геозону, перейдите на вкладку <span className='emphasized'>"Карта"</span>.</div>
                <div>Третий пункт в меню - настройка геозон.</div>
                <div>Нажав на кнопку <span className='emphasized'>"Построить новую геозону"</span>, Вы сможете ввести ее название, и задать границы.</div>
                <div>Чтобы завершить построение геозоны, Вам необходимо кликнуть на первую точку ее границы, после этого Вы сможете указать, по каким авто необходимо отслеживать выезды за ее пределы.</div>
                <div>При выезде авто за границы геозоны, Вы будете получать уведомления об этом на карте и на Вашем дашборде.</div>
                <div className='MT-8'>Если Вы хотите чтобы авто самостоятельно глушилось при выезде из геозоны, Вы можете перейти в профиль авто, и установить галку <span className='emphasized'>"Автоматическая блокировка двигателя при выезде из геозоны"</span>.</div>
            </>,

        textSML: 
            <>
                <div>Недоступно для мобильной версии приложения.</div>
            </>,

        urlBIG: 'https://youtu.be/UY7R94CHGHc?feature=shared',
        urlSML: null,
    },
]