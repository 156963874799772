import React, { useState, useEffect } from 'react';

import { educationBase } from './constants';
import { Card } from './Card';
import { Navbar } from './Navbar';
import './style.css'

export function Education() {

    const [size, setSize] = useState('BIG')
    const [educationBaseState, setEducationBaseState] = useState(educationBase)
    const [render, setRender] = useState(0)

    const onresize = () => {
        if (document.documentElement.clientWidth > 1024){
            setSize('BIG')
        } else {
            setSize('SML')
        }
    }

    window.onresize = onresize

    useEffect(() => {
        onresize()
    }, []);

    return (
        <div className={'education' + size}>
            <Navbar size={size} educationBaseState={educationBaseState} setEducationBaseState={setEducationBaseState} render={render} setRender={setRender}/>
            {educationBaseState.map((info) => 
                <Card info={info} size={size} educationBaseState={educationBaseState} setEducationBaseState={setEducationBaseState} render={render} setRender={setRender}/>
            )}
        </div>
    )
}