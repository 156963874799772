import React from "react"
import {Route, Routes} from "react-router-dom"
import { Landing } from './pages'
import { Education } from './pages'
import { Franchise } from './pages'

const routes = [
    {path: '/education', component: Education, exact: true},
    {path: '/franchise', component: Franchise, exact: true},
    {path: '/', component: Landing, exact: true},
    {path: '*', component: Landing, exact: true},
]

const AppRouter = () => {
    return (
        <Routes>
        {routes.map(route => 
            <Route
                element={<route.component/>}
                path={route.path}
                exact={route.exact}
                key={route.path}
            />
        )}
        </Routes>
    )
}

export default AppRouter;