import React from 'react';

import _1_offer_icon from '../icons/_1_offer_icon.svg';
import _2_offer_icon from '../icons/_2_offer_icon.svg';
import _3_offer_icon from '../icons/_3_offer_icon.svg';
import _4_offer_icon from '../icons/_4_offer_icon.svg';
import _5_offer_icon from '../icons/_5_offer_icon.svg';
import _6_offer_icon from '../icons/_6_offer_icon.svg';
import _7_offer_icon from '../icons/_7_offer_icon.svg';
import './style.css'

export function OfferBlock({ size, offerRef }) {

    return (
        <>
        {size === 'BIG'
        ?
            <div className='OfferBlock' ref={offerRef}>
                <div className='bodyOfferBlock'>
                    <div className='titleOfferBlock'>
                        Что мы предоставляем нашим партнерам?
                    </div>
                    <div className='elemsOfferBlock'>
                        <div className='elementOfferBlock smlElementOfferBlock'>
                            <div className='divIconElementOfferBlock'>
                                <img src={_1_offer_icon} alt='' className='iconElementOfferBlock'/>
                            </div>
                            <div className='textElementOfferBlock'>
                                Техническая поддержка для клиентов. Все что вам остается - делать продажи
                            </div>
                            <div></div>
                        </div>
                        <div className='elementOfferBlock smlElementOfferBlock'>
                            <div className='divIconElementOfferBlock'>
                                <img src={_2_offer_icon} alt='' className='iconElementOfferBlock'/>
                            </div>
                            <div className='textElementOfferBlock'>
                                Надежный айти продукт, о котором вам не нужно беспокоиться
                            </div>
                            <div></div>
                        </div>
                        <div className='elementOfferBlock smlElementOfferBlock'>
                            <div className='divIconElementOfferBlock'>
                                <img src={_3_offer_icon} alt='' className='iconElementOfferBlock'/>
                            </div>
                            <div className='textElementOfferBlock'>
                                Обучаем автоэлектриков, предоставляем карты монтажа для каждого авто
                            </div>
                            <div></div>
                        </div>
                        <div className='elementOfferBlock smlElementOfferBlock'>
                            <div className='divIconElementOfferBlock'>
                                <img src={_4_offer_icon} alt='' className='iconElementOfferBlock'/>
                            </div>
                            <div className='textElementOfferBlock'>
                                Полностью берем на себя поставки оборудования
                            </div>
                            <div></div>
                        </div>
                        <div className='elementOfferBlock bigElementOfferBlock'>
                            <div className='divIconElementOfferBlock'>
                                <img src={_5_offer_icon} alt='' className='iconElementOfferBlock'/>
                            </div>
                            <div className='textElementOfferBlock'>
                                Полное обучение наших партнеров всем сложностям бизнеса - мы заинтересованы зарабатывать вместе с вами
                            </div>
                            <div></div>
                        </div>
                        <div className='elementOfferBlock bigElementOfferBlock'>
                            <div className='divIconElementOfferBlock'>
                                <img src={_6_offer_icon} alt='' className='iconElementOfferBlock'/>
                            </div>
                            <div className='textElementOfferBlock'>
                                Мы даем нашим партнерам самые актуальные базы потенциальных клиентов по интересующим регионам
                            </div>
                            <div></div>
                        </div>
                        <div className='elementOfferBlock bigElementOfferBlock'>
                            <div className='divIconElementOfferBlock'>
                                <img src={_7_offer_icon} alt='' className='iconElementOfferBlock'/>
                            </div>
                            <div className='textElementOfferBlock'>
                                Мы оказываем помощь при подборе менеджеров по продажам, обучаем им, даем скрипты для продаж
                            </div>
                            <div></div>
                        </div>
                    </div>
                </div>
            </div>
        :
        <div className='OfferBlock_mob' ref={offerRef}>
            <div className='bodyOfferBlock_mob'>
                <div className='titleOfferBlock_mob'>
                    Что мы предоставляем нашим партнерам?
                </div>
                <div className='elemsOfferBlock_mob'>
                    <div className='elementOfferBlock_mob'>
                        <div className='divIconElementOfferBlock_mob'>
                            <img src={_1_offer_icon} alt='' className='iconElementOfferBlock_mob'/>
                        </div>
                        <div className='textElementOfferBlock_mob'>
                            Техническая поддержка для клиентов. Все что вам остается - делать продажи
                        </div>
                        <div></div>
                    </div>
                    <div className='elementOfferBlock_mob'>
                        <div className='divIconElementOfferBlock_mob'>
                            <img src={_2_offer_icon} alt='' className='iconElementOfferBlock_mob'/>
                        </div>
                        <div className='textElementOfferBlock_mob'>
                            Надежный айти продукт, о котором вам не нужно беспокоиться
                        </div>
                        <div></div>
                    </div>
                    <div className='elementOfferBlock_mob'>
                        <div className='divIconElementOfferBlock_mob'>
                            <img src={_3_offer_icon} alt='' className='iconElementOfferBlock_mob'/>
                        </div>
                        <div className='textElementOfferBlock_mob'>
                            Обучаем автоэлектриков, предоставляем карты монтажа для каждого авто
                        </div>
                        <div></div>
                    </div>
                    <div className='elementOfferBlock_mob'>
                        <div className='divIconElementOfferBlock_mob'>
                            <img src={_4_offer_icon} alt='' className='iconElementOfferBlock_mob'/>
                        </div>
                        <div className='textElementOfferBlock_mob'>
                            Полностью берем на себя поставки оборудования
                        </div>
                        <div></div>
                    </div>
                    <div className='elementOfferBlock_mob'>
                        <div className='divIconElementOfferBlock_mob'>
                            <img src={_5_offer_icon} alt='' className='iconElementOfferBlock_mob'/>
                        </div>
                        <div className='textElementOfferBlock_mob'>
                            Полное обучение наших партнеров всем сложностям бизнеса - мы заинтересованы зарабатывать вместе с вами
                        </div>
                        <div></div>
                    </div>
                    <div className='elementOfferBlock_mob'>
                        <div className='divIconElementOfferBlock_mob'>
                            <img src={_6_offer_icon} alt='' className='iconElementOfferBlock_mob'/>
                        </div>
                        <div className='textElementOfferBlock_mob'>
                            Мы даем нашим партнерам самые актуальные базы потенциальных клиентов по интересующим регионам
                        </div>
                        <div></div>
                    </div>
                    <div className='elementOfferBlock_mob'>
                        <div className='divIconElementOfferBlock'>
                            <img src={_7_offer_icon} alt='' className='iconElementOfferBlock_mob'/>
                        </div>
                        <div className='textElementOfferBlock_mob'>
                            Мы оказываем помощь при подборе менеджеров по продажам, обучаем им, даем скрипты для продаж
                        </div>
                        <div></div>
                    </div>
                </div>
            </div>
        </div>
        }
        </>
    )
}