import React, { useState } from 'react';
import '../../styles/components/components.css'

export default function Btn_defolt(props) {

    let btn_style_defolt = {
        'height': props.height,
        'width': props.width,
        'color': props.text_color,
    }

    let btn_style_hover = {
        'height': props.height,
        'width': props.width,
        'color': props.text_color,
    }

    const [style, setStyle] = useState(btn_style_defolt)

    function click_btn(){
        props.onClick()
    }

    function MouseEnter(){
        setStyle(btn_style_hover)
    }

    function onMouseLeave(){
        setStyle(btn_style_defolt)
    }

    return (
        <div className='div_btn_defolt_icon' style={style} onMouseEnter={MouseEnter} onMouseLeave={onMouseLeave} onClick={click_btn}>
            <img src={props.icon} alt='' className=''/>
            <div className='text_btn_defolt_icon'>{props.text}</div>
        </div>
    )
}