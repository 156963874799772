import React, { useState } from 'react'

import tg_icon from '../../../styles/icons/tg_icon.svg'
import tg_icon_mob from '../../../styles/icons/tg_icon_mob.svg'
import wa_icon from '../../../styles/icons/wa_icon.svg'
import wa_icon_mob from '../../../styles/icons/wa_icon_mob.svg'
import phone_big from '../../../styles/icons/phone_big.png'

import BasicInput from '../../../components/UI/basic_input.jsx'
import BtnDefolt from '../../../components/UI/btn_defolt.jsx'
import Modal from '../../../components/UI/basic_modal'

import axios_api from '../../../API/axios_api'

import './style.css'

export function PutBlock({ size, putRef }) {

    const [name, setName] = useState('')
    const [phone, setPhone] = useState('')
    const [nameStatus, setNameStatus] = useState('active')
    const [phoneStatus, setPhoneStatus] = useState('active')
    const [checkbox, setCheckbox] = useState(false)
    const [checkboxStatus, setCheckboxStatus] = useState(false)
    const [sended, setSended] = useState(false)

    const send = async function(){
        if (name === ''){
            setNameStatus('error')
            setTimeout(
                function(){
                    setNameStatus('active')
                }, 1000
            );
            return
        }
        if (phone === ''){
            setPhoneStatus('error')
            setTimeout(
                function(){
                    setPhoneStatus('active')
                }, 1000
            );
            return
        }
        if (checkbox === false){
            setCheckboxStatus(true)
            setTimeout(
                function(){
                    setCheckboxStatus(false)
                }, 1000
            );
            return
        }

        let post_clients_response = await axios_api.post_clients(name + ' (Франшиза)', phone)

        if (post_clients_response.status === 201){
            setSended(true)
            setName('')
            setPhone('')
            setCheckbox(false)
        }
    }

    const go_privacy = () => {
        if (process.env.REACT_APP_SERVER == 'PROD') {
            window.location.href = "http://crm.controlgps.org/privacy_policy";
        } else {
            window.location.href = "http://dev.crm.controlgps.org/privacy_policy";
        }
    }

    return (
        <>
        {size === 'BIG'
        ?
        <div className='PutBlock'>
            <div className='bodyPutBlock' ref={putRef}>
                <div style={{width: '50%', 'marginTop': 'auto', 'marginBottom': 'auto'}}>
                    <img src={phone_big} alt='' className='phoneIconPutBlock'/>
                </div>
                <div className='cardPutBlock'>
                    <div className='textPutBlock'>
                        Самостоятельно убедитесь в удобстве нашей системы
                    </div>
                    <div className='inputsPutBlock'>
                        <BasicInput value={name} text={'Ваше имя'} status={nameStatus} width={'400px'} type={'text'} onChange={setName}/>
                    </div>
                    <div className='inputsPutBlock'>
                        <BasicInput value={phone} text={'Телефон'} status={phoneStatus} width={'400px'} type={'text'} onChange={setPhone}/>
                    </div>
                    <div className='soglPutBlock'>
                        <input className='form-check-input' style={{'marginTop': '0'}} checked={checkbox} type='checkbox' onChange={() => setCheckbox(!checkbox)}/>
                        <div style={{marginLeft: '8px', cursor: 'pointer'}} className={checkboxStatus ? 'danger' : ''} onClick={go_privacy}>
                            Даю согласие на обработку <span className={checkboxStatus ? 'danger underline' : 'emphasized underline'}>Персональных данных</span>
                        </div>
                    </div>
                    <div className='btnPutBlock'>
                        <BtnDefolt color={'#6486FB'} text_color={'#FFFFFF'} hover_color={'#5179fc'} text={'Оставить заявку'} height={'50px'} width={'400px'} onClick={send}/>
                    </div>
                    <div className='iconsPutCard'>
                        <a href="https://wa.me/+74952150715" target={'_blank'}>
                            <img src={wa_icon} alt="" className='waPutCard'/>
                        </a>
                        <a href="https://t.me/controlgps" target={'_blank'} className='tgPutCard'>
                            <img src={tg_icon} alt=""/>
                        </a>

                        <a href="tel:+7(495)215-07-15" className='phonePutCard'>
                            +7 (495) 215-07-15
                        </a>
                    </div>
                </div>
            </div>
            <Modal active={sended} setActive={setSended}>
                <div className='tyDiv'>
                    <div className='tyHeader'>
                        Ваша заявка успешно оставлена!
                    </div>
                    <div className='tyText'>
                        Благодарим вас за интерес к ControlGPS. Наши менеджеры свяжутся с вами в ближайший рабочий день.
                    </div>
                    <div className='tyBtn'>
                        <BtnDefolt color={'#EFF3FF'} text_color={'#3C4A59'} hover_color={'#dbe4ff'} text={'Закрыть'} height={'50px'} width={'300px'} onClick={() => setSended(false)}/>
                    </div>
                </div>
            </Modal>
        </div>
        :
        <div className='PutBlock_mob'>
            <div className='bodyPutBlock_mob' ref={putRef}>
                <div className='cardPutBlock_mob'>
                    <div className='textPutBlock_mob'>
                        Самостоятельно убедитесь в удобстве нашей системы
                    </div>
                    <div className='inputsPutBlock_mob'>
                        <BasicInput value={name} text={'Ваше имя'} status={nameStatus} width={'100%'} type={'text'} onChange={setName}/>
                    </div>
                    <div className='inputsPutBlock_mob'>
                        <BasicInput value={phone} text={'Телефон'} status={phoneStatus} width={'100%'} type={'text'} onChange={setPhone}/>
                    </div>
                    <div className='soglPutBlock_mob'>
                        <div style={{'width': '14px', paddingTop: '1px'}}>
                            <input className='form-check-input' style={{'marginTop': '0', 'width': '14px'}} checked={checkbox} type='checkbox' onChange={() => setCheckbox(!checkbox)}/>
                        </div>                        
                        <div style={{marginLeft: '8px', cursor: 'pointer'}} className={checkboxStatus ? 'danger' : ''} onClick={go_privacy}>
                            Даю согласие на обработку <span className={checkboxStatus ? 'danger' : 'emphasized'}>Персональных данных</span>
                        </div>
                    </div>
                    <div className='btnPutBlock_mob'>
                        <BtnDefolt color={'#6486FB'} text_color={'#FFFFFF'} hover_color={'#5179fc'} text={'Оставить заявку'} height={'40px'} width={'100%'} onClick={send}/>
                    </div>
                    <div className='iconsPutCard_mob'>
                        <a href="https://wa.me/+74952150715" target={'_blank'}>
                            <img src={wa_icon_mob} alt="" className='waPutCard_mob'/>
                        </a>
                        <a href="https://t.me/controlgps" target={'_blank'} className='tgPutCard_mob'>
                            <img src={tg_icon_mob} alt=""/>
                        </a>
                        <a href="tel:+7(495)215-07-15" className='phonePutCard_mob'>
                            +7 (495) 215-07-15
                        </a>
                    </div>
                </div>
                <div style={{'marginTop': '48px'}}>
                    <img src={phone_big} alt='' className='phoneIconPutBlock_mob'/>
                </div>
            </div>
            <Modal active={sended} setActive={setSended}>
                <div className='tyDiv_mob'>
                    <div className='tyHeader_mob'>
                        Ваша заявка успешно оставлена!
                    </div>
                    <div className='tyText_mob'>
                        Благодарим вас за интерес к ControlGPS. Наши менеджеры свяжутся с вами в ближайший рабочий день.
                    </div>
                    <div className='tyBtn_mob'>
                        <BtnDefolt color={'#EFF3FF'} text_color={'#3C4A59'} hover_color={'#dbe4ff'} text={'Вернуться на главную страницу'} height={'50px'} width={'300px'} onClick={() => setSended(false)}/>
                    </div>
                </div>
            </Modal>
        </div>
        }
        </>
    )
}