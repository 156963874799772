import React from 'react';

import './style.css'

export function ModelBlock({ size, modelRef }) {

    return (
        <>
        {size === 'BIG'
        ?
            <div className='ModelBlock' ref={modelRef}>
                <div className='bodyModelBlock'>
                    <div className='titleModelBlock'>
                        Бизнес-модель
                    </div>
                    <div className='textModelBlock'>
                        Компании GPS-мониторинга продают оборудование и занимаются его установкой
                    </div>
                    <div className='rowModelBlock'>
                        <div className='cardModelBlock greyCard'> 
                            <div className='titleCardModelBlock danger'>
                                8 000 ₽
                            </div>
                            <div className='textCardModelBlock'>
                                Рыночная стоимость GPS-трекера с установкой у наших конкурентов
                            </div>
                            <div className='bodyCardModelBlock'>
                                <div className='titleBodyCardModelBlock'>
                                    Оплата у конкурентов
                                </div>
                                <div className='textBodyCardModelBlock'>
                                    За каждое авто, подключенное к системе, наши конкуренты берут по <span className='danger'>500 ₽ в месяц</span>
                                </div>
                            </div>
                        </div>
                        <div className='cardModelBlock blueCard'> 
                            <div className='titleCardModelBlock white'>
                                2 000 ₽
                            </div>
                            <div className='textCardModelBlock white'>
                                Стоимость оборудования для партнеров ControlGPS — это позволяет агрессивно занимать региональный рынок
                            </div>
                            <div className='bodyCardModelBlock'>
                                <div className='titleBodyCardModelBlock'>
                                    Оплата у ControlGPS
                                </div>
                                <div className='textBodyCardModelBlock'>
                                    Мы предоставляем диллерам подписку всего по <span className='success'>100 ₽ в месяц</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        :
            <div className='ModelBlock_mob' ref={modelRef}>
                <div className='bodyModelBlock_mob'>
                    <div className='titleModelBlock_mob'>
                        Бизнес-модель
                    </div>
                    <div className='textModelBlock_mob'>
                        Компании GPS-мониторинга продают оборудование и занимаются его установкой
                    </div>
                    <div className='cardModelBlock_mob greyCard'> 
                        <div className='titleCardModelBlock_mob danger'>
                            8 000 ТЫС ₽
                        </div>
                        <div className='textCardModelBlock_mob'>
                            Рыночная стоимость GPS-трекера с установкой у наших конкурентов
                        </div>
                        <div className='bodyCardModelBlock_mob'>
                            <div className='titleBodyCardModelBlock_mob'>
                                Оплата у конкурентов
                            </div>
                            <div className='textBodyCardModelBlock_mob'>
                                За каждое авто, подключенное к системе, наши конкуренты берут по <span className='danger'>500 ₽ в месяц</span>
                            </div>
                        </div>
                    </div>
                    <div className='cardModelBlock_mob blueCard'> 
                        <div className='titleCardModelBlock_mob white'>
                            2 000 ТЫС ₽
                        </div>
                        <div className='textCardModelBlock_mob white'>
                            Стоимость оборудования для партнеров ControlGPS — это позволяет агрессивно занимать региональный рынок
                        </div>
                        <div className='bodyCardModelBlock_mob'>
                            <div className='titleBodyCardModelBlock_mob'>
                                Оплата у ControlGPS
                            </div>
                            <div className='textBodyCardModelBlock_mob'>
                                Мы предоставляем диллерам подписку всего по <span className='success'>100 ₽ в месяц</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        }
        </>
    )
}