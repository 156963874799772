import React from 'react';

import oborud_1 from '../../../styles/icons/oborud_1.png';
import oborud_2 from '../../../styles/icons/oborud_2.png';
import dot from '../../../styles/icons/dot.svg';
import dot_mob from '../../../styles/icons/dot_mob.svg';
import oborud_1_mob from '../../../styles/icons/oborud_1_mob.png';
import oborud_2_mob from '../../../styles/icons/oborud_2_mob.png';

import BtnDefolt from '../../../components/UI/btn_defolt.jsx'
import './style.css'

export function EquipmentBlock({ size, tryFree }) {

    return (
        <>
        {size === 'BIG'
        ?
        <div className='EquipmentBlock'>
            <div className='bodyEquipmentBlock'>
                <div className='headerEquipmentBlock'>
                    Оборудование
                </div>
                <div className='cardsEquipmentBlock'>
                    <div className='cardEquipmentBlock' style={{'marginRight': '16px'}}>
                        <img src={oborud_1} alt='' className='oborud_1IconEquipmentBlock' />
                        <div className='nameEquipmentBlock'>
                            Трекер <span className='emphasized'>ControlGPS</span> 
                        </div>
                        <div className='priceEquipmentBlock'>
                            За <span className='emphasized'>2499₽</span> вы получаете современный трекер <span className='emphasized'>ControlGPS</span> с максимальным функционалом:
                        </div>
                        <div className='itemEquipmentBlock'>
                            <img src={dot} alt='' className='dotIconEquipmentBlock' />
                            <div>
                                Мониторинг местоположения в режиме онлайн
                            </div>
                        </div>
                        <div className='itemEquipmentBlock'>
                            <img src={dot} alt='' className='dotIconEquipmentBlock' />
                            <div>
                                Возможность глушения двигателя по команде
                            </div>
                        </div>
                        <div className='itemEquipmentBlock'>
                            <img src={dot} alt='' className='dotIconEquipmentBlock' />
                            <div>
                                Запись звука в салоне
                            </div>
                        </div>
                        <div className='itemEquipmentBlock'>
                            <img src={dot} alt='' className='dotIconEquipmentBlock' />
                            <div>
                                Моментальное оповещения о выезде из геозоны, и об открытии дверей
                            </div>
                        </div>
                        <div className='btnEquipmentBlock'>
                            <BtnDefolt color={'#6486FB'} text_color={'#FFFFFF'} hover_color={'#5179fc'} text={'Оставить заявку'} height={'50px'} width={'200px'} onClick={tryFree}/>
                        </div>
                    </div>
                    <div className='cardEquipmentBlock' style={{'marginLeft': '16px'}}>
                    <img src={oborud_2} alt='' className='oborud_1IconEquipmentBlock' />
                        <div className='nameEquipmentBlock'>
                            Автономный маяк <span className='emphasized'>ControlGPS</span> 
                        </div>
                        <div className='priceEquipmentBlock'>
                            За  <span className='emphasized'>3499₽</span> вы получаете возможность отслеживать ваше авто без необходимости подключать трекер к цепи авто                        
                        </div>
                        <div className='itemEquipmentBlock'>
                            <img src={dot} alt='' className='dotIconEquipmentBlock' />
                            <div>
                                Передача координат раз в час и по запросу
                            </div>
                        </div>
                        <div className='itemEquipmentBlock'>
                            <img src={dot} alt='' className='dotIconEquipmentBlock' />
                            <div>
                                Автономная работа от батареи до 3х лет
                            </div>
                        </div>
                        <div className='itemEquipmentBlock'>
                            <img src={dot} alt='' className='dotIconEquipmentBlock' />
                            <div>
                                Маяк тяжело найти, поэтому угнать авто с ним практически невозможно
                            </div>
                        </div>
                        <div className='btnEquipmentBlock'>
                            <BtnDefolt color={'#6486FB'} text_color={'#FFFFFF'} hover_color={'#5179fc'} text={'Оставить заявку'} height={'50px'} width={'200px'} onClick={tryFree}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        :
        <div className='EquipmentBlock_mob'>
            <div className='bodyEquipmentBlock_mob'>
                <div className='cardsEquipmentBlock_mob'>
                    <div className='headerEquipmentBlock_mob'>
                        Оборудование
                    </div>
                    <div className='cardEquipmentBlock_mob'>
                        <img src={oborud_1_mob} alt='' className='oborud_1IconEquipmentBlock_mob' />
                        <div className='nameEquipmentBlock_mob'>
                            Трекер <span className='emphasized'>ControlGPS</span> 
                        </div>
                        <div className='priceEquipmentBlock_mob'>
                            За <span className='emphasized'>2499₽</span> вы получаете современный трекер <span className='emphasized'>ControlGPS</span> с максимальным функционалом:
                        </div>
                        <div className='itemEquipmentBlock_mob'>
                            <img src={dot_mob} alt='' className='dotIconEquipmentBlock_mob' />
                            <div>
                                Мониторинг местоположения в режиме онлайн
                            </div>
                        </div>
                        <div className='itemEquipmentBlock_mob'>
                            <img src={dot_mob} alt='' className='dotIconEquipmentBlock_mob' />
                            <div>
                                Возможность глушения двигателя по команде
                            </div>
                        </div>
                        <div className='itemEquipmentBlock_mob'>
                            <img src={dot_mob} alt='' className='dotIconEquipmentBlock_mob' />
                            <div>
                                Запись звука в салоне
                            </div>
                        </div>
                        <div className='itemEquipmentBlock_mob'>
                            <img src={dot_mob} alt='' className='dotIconEquipmentBlock_mob' />
                            <div>
                                Моментальное оповещения о выезде из геозоны, и об открытии дверей
                            </div>
                        </div>
                        <div className='btnEquipmentBlock_mob'>
                            <BtnDefolt color={'#6486FB'} text_color={'#FFFFFF'} hover_color={'#5179fc'} text={'Оставить заявку'} height={'40px'} width={'160px'} onClick={tryFree}/>
                        </div>
                    </div>
                    <div className='cardEquipmentBlock_mob'>
                    <img src={oborud_2_mob} alt='' className='oborud_1IconEquipmentBlock_mob' />
                        <div className='nameEquipmentBlock_mob'>
                            Автономный маяк <span className='emphasized'>ControlGPS</span> 
                        </div>
                        <div className='priceEquipmentBlock_mob'>
                            За  <span className='emphasized'>3499₽</span> вы получаете возможность отслеживать ваше авто без необходимости подключать трекер к цепи авто                        
                        </div>
                        <div className='itemEquipmentBlock_mob'>
                            <img src={dot_mob} alt='' className='dotIconEquipmentBlock_mob' />
                            <div>
                                Передача координат раз в час и по запросу
                            </div>
                        </div>
                        <div className='itemEquipmentBlock_mob'>
                            <img src={dot_mob} alt='' className='dotIconEquipmentBlock_mob' />
                            <div>
                                Автономная работа от батареи до 3х лет
                            </div>
                        </div>
                        <div className='itemEquipmentBlock_mob'>
                            <img src={dot_mob} alt='' className='dotIconEquipmentBlock_mob' />
                            <div>
                                Маяк тяжело найти, поэтому угнать авто с ним практически невозможно
                            </div>
                        </div>
                        <div className='btnEquipmentBlock_mob'>
                            <BtnDefolt color={'#6486FB'} text_color={'#FFFFFF'} hover_color={'#5179fc'} text={'Оставить заявку'} height={'40px'} width={'160px'} onClick={tryFree}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        }
        </>
    )
}