import React from 'react';

import graf_1 from '../../../styles/icons/graf_1.svg';
import graf_2 from '../../../styles/icons/graf_2.svg';
import curved_left from '../../../styles/icons/curved_left.svg';
import curved_right from '../../../styles/icons/curved_right.svg';
import car from '../../../styles/icons/car.png';
import car_mob from '../../../styles/icons/car_mob.png';

import BtnDefolt from '../../../components/UI/btn_defolt.jsx'

import './style.css'

export function BenefitBlock({ size, tryFree, benefitsRef }) {

    return (
        <>
        {size === 'BIG'
        ?
        <div className='BenefitBlock'>
            <div className='bodyBenefitBlock'>
                <div className='headerBenefitBlock' ref={benefitsRef}>
                    Мы посчитали <span className='emphasized'>Вашу выгоду:</span>
                </div>
                <div className='cardsBenefitBlock'>
                    <div className='cardBenefitBlock cardGrey' style={{'marginRight': '16px'}}>
                        <div className='cashNowBenefitBlock'>
                            Сколько вы тратите сейчас:
                        </div>
                        <div className='srTrBenefitBlock'>
                            Средняя стоимость трекера: <span className='danger'>6000₽</span>
                        </div>
                        <div className='srPoBenefitBlock' style={{marginBottom: '16px'}}>
                            Средняя стоимость подписки: <span className='danger'>400₽/авто</span>
                        </div>
                        <div className='smlCardBenefitBlock'>
                            <div className='headerSmlCardBenefitBlock'>
                                При парке на 300 автомобилей:
                            </div>
                            <div className='rowSmlCardBenefitBlock'>
                                <div className='digsSmlCardBenefitBlock'>
                                    <div className='digSmlCardBenefitBlock'>
                                        <div className='digValSmlCardBenefitBlock danger'>
                                            <div className='digVal1SmlCardBenefitBlock'>
                                                1.8
                                            </div>
                                            <div className='digVal2SmlCardBenefitBlock'>
                                                млн ₽
                                            </div>
                                        </div>
                                        <div className='digTextSmlCardBenefitBlock'>
                                            Оборудование
                                        </div>
                                    </div>
                                    <div className='digSmlCardBenefitBlock' style={{ marginTop: '16px'}}>
                                        <div className='digValSmlCardBenefitBlock warning'>
                                            <div className='digVal1SmlCardBenefitBlock'>
                                                1.44
                                            </div>
                                            <div className='digVal2SmlCardBenefitBlock'>
                                                млн ₽
                                            </div>
                                        </div>
                                        <div className='digTextSmlCardBenefitBlock'>
                                            Обслуживание за год
                                        </div>
                                    </div>
                                </div>
                                <div className='grafBenefitBlock'>
                                    <img src={graf_1} alt='' className='grafIconBenefitBlock'/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='cardBenefitBlock cardBlue' style={{'marginLeft': '16px'}}>
                        <div className='cashNowBenefitBlock white'>
                            При использовании ControlGPS:
                        </div>
                        <div className='srTrBenefitBlock white'>
                            Средняя стоимость трекера: <span className='white'>2499₽</span>
                        </div>
                        <div className='srPoBenefitBlock white' style={{marginBottom: '16px'}}>
                            Cтоимость подписки: <span className='white'>150₽/авто</span>
                        </div>
                        <div className='smlCardBenefitBlock'>
                            <div className='headerSmlCardBenefitBlock'>
                                При парке на 300 автомобилей:
                            </div>
                            <div className='rowSmlCardBenefitBlock'>
                                <div className='digsSmlCardBenefitBlock'>
                                    <div className='digSmlCardBenefitBlock'>
                                        <div className='digValSmlCardBenefitBlock success'>
                                            <div className='digVal1SmlCardBenefitBlock'>
                                                750
                                            </div>
                                            <div className='digVal2SmlCardBenefitBlock'>
                                                тыс ₽
                                            </div>
                                        </div>
                                        <div className='digTextSmlCardBenefitBlock'>
                                            Оборудование
                                        </div>
                                    </div>
                                    <div className='digSmlCardBenefitBlock' style={{ marginTop: '16px'}}>
                                        <div className='digValSmlCardBenefitBlock emphasized'>
                                            <div className='digVal1SmlCardBenefitBlock'>
                                                360 
                                            </div>
                                            <div className='digVal2SmlCardBenefitBlock'>
                                                тыс ₽
                                            </div>
                                        </div>
                                        <div className='digTextSmlCardBenefitBlock'>
                                            Обслуживание за год
                                        </div>
                                    </div>
                                </div>
                                <div className='grafBenefitBlock'>
                                    <img src={graf_2} alt='' className='grafIconBenefitBlock'/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='curvedes'>
                    <img src={curved_left} alt='' className='curved_leftIconBenefitBlock'/>
                    <img src={curved_right} alt='' className='curved_rightIconBenefitBlock'/>
                </div>
                <div className='cardYourBenefit'>
                    <div className='cardBodyYourBenefit cardBlue'>
                        <div className='smlCardYourBenefit'>
                            <div className='headerCardYourBenefit'>
                                Ваша выгода:
                            </div>
                            <div className='digsCardYourBenefit'>
                                <div className='digCardYourBenefit'>
                                    <div className='digValCardYourBenefit success'>
                                        <div className='digVal1CardYourBenefit'>
                                            1.1
                                        </div>
                                        <div className='digVal2CardYourBenefit'>
                                            млн ₽
                                        </div>
                                    </div>
                                    <div className='digTextCardYourBenefit'>
                                        На оборудовании
                                    </div>
                                </div>
                                <div className='digCardYourBenefit' style={{marginLeft: '24px'}}>
                                    <div className='digValCardYourBenefit emphasized'>
                                        <div className='digVal1CardYourBenefit'>
                                            1.08
                                        </div>
                                        <div className='digVal2CardYourBenefit'>
                                            млн ₽
                                        </div>
                                    </div>
                                    <div className='digTextCardYourBenefit'>
                                        На обслуживании ежегодно
                                    </div>
                                </div>
                            </div>
                            <div className='youCanCardYourBenefit'>
                                Вы можете экономить от <span className='emphasized'>50%</span> на оборудовании и от <span className='emphasized'>70%</span> на обслуживании!
                            </div>
                            <div className='btnCardYourBenefit'>
                                <BtnDefolt color={'#6486FB'} text_color={'#FFFFFF'} hover_color={'#5179fc'} text={'Начать пользоваться'} height={'50px'} width={'200px'} onClick={tryFree}/>
                            </div>
                        </div>
                        <img src={car} alt='' className='carIconBenefitBlock'/>
                    </div>
                </div>
            </div>
        </div>
        :
        <div className='BenefitBlock_mob'>
            <div className='bodyBenefitBlock_mob'>
                <div className='headerBenefitBlock_mob' ref={benefitsRef}>
                    Мы посчитали <span className='emphasized'>Вашу выгоду:</span>
                </div>
                <div className='cardBenefitBlock_mob cardGrey'>
                    <div className='cashNowBenefitBlock_mob'>
                        Сколько вы тратите сейчас:
                    </div>
                    <div className='srTrBenefitBlock_mob' style={{'marginTop': '16px'}}>
                        <div>
                            Средняя стоимость трекера:
                        </div>
                        <div style={{'fontSize': '22px', 'marginTop': '4px'}}>
                            <span className='danger'>6000₽</span>
                        </div>
                    </div>
                    <div className='srTrBenefitBlock_mob' style={{'marginTop': '12px'}}>
                        <div>
                            Средняя стоимость подписки:
                        </div>
                        <div style={{'fontSize': '22px', 'marginTop': '4px'}}>
                            <span className='danger'>400₽/авто</span>
                        </div>
                    </div>
                    <div className='smlCardBenefitBlock_mob'>
                        <div className='headerSmlCardBenefitBlock_mob'>
                            При парке на 300 автомобилей:
                        </div>
                        <div className='digSmlCardBenefitBlock_mob'>
                            <div className='digValSmlCardBenefitBlock_mob danger'>
                                <div className='digVal1SmlCardBenefitBlock_mob'>
                                    1.8
                                </div>
                                <div className='digVal2SmlCardBenefitBlock_mob'>
                                    млн ₽
                                </div>
                            </div>
                            <div className='digTextSmlCardBenefitBlock_mob'>
                                Оборудование
                            </div>
                        </div>
                        <div className='digSmlCardBenefitBlock_mob' style={{ marginTop: '16px'}}>
                            <div className='digValSmlCardBenefitBlock_mob warning'>
                                <div className='digVal1SmlCardBenefitBlock_mob'>
                                    1.44
                                </div>
                                <div className='digVal2SmlCardBenefitBlock_mob'>
                                    млн ₽
                                </div>
                            </div>
                            <div className='digTextSmlCardBenefitBlock_mob'>
                                Обслуживание за год
                            </div>
                        </div>
                        <div className='grafBenefitBlock'>
                            <img src={graf_1} alt='' className='grafIconBenefitBlock_mob'/>
                        </div>
                    </div>
                </div>
                <div className='cardBenefitBlock_mob cardBlue'>
                    <div className='cashNowBenefitBlock_mob white'>
                        При использовании ControlGPS:
                    </div>
                    <div className='srTrBenefitBlock_mob white' style={{'marginTop': '16px'}}>
                        <div>
                            Средняя стоимость трекера:
                        </div>
                        <div style={{'fontSize': '22px', 'marginTop': '4px'}}>
                            <span className='white'>2499₽</span>
                        </div>
                    </div>
                    <div className='srTrBenefitBlock_mob white' style={{'marginTop': '12px'}}>
                        <div>
                            Стоимость подписки:
                        </div>
                        <div style={{'fontSize': '22px', 'marginTop': '4px'}}>
                            <span className='white'>150₽/авто</span>
                        </div>
                    </div>
                    <div className='smlCardBenefitBlock_mob'>
                        <div className='headerSmlCardBenefitBlock_mob'>
                            При парке на 300 автомобилей:
                        </div>
                        <div className='digSmlCardBenefitBlock_mob'>
                            <div className='digValSmlCardBenefitBlock_mob success'>
                                <div className='digVal1SmlCardBenefitBlock_mob'>
                                    750
                                </div>
                                <div className='digVal2SmlCardBenefitBlock_mob'>
                                    тыч ₽
                                </div>
                            </div>
                            <div className='digTextSmlCardBenefitBlock_mob'>
                                Оборудование
                            </div>
                        </div>
                        <div className='digSmlCardBenefitBlock_mob' style={{ marginTop: '16px'}}>
                            <div className='digValSmlCardBenefitBlock_mob emphasized'>
                                <div className='digVal1SmlCardBenefitBlock_mob'>
                                    360
                                </div>
                                <div className='digVal2SmlCardBenefitBlock_mob'>
                                    тыс ₽
                                </div>
                            </div>
                            <div className='digTextSmlCardBenefitBlock_mob'>
                                Обслуживание за год
                            </div>
                        </div>
                        <div className='grafBenefitBlock'>
                            <img src={graf_2} alt='' className='grafIconBenefitBlock_mob'/>
                        </div>
                    </div>
                </div>
                <div className='curvedes_mob'>
                    <img src={curved_left} alt='' className='curved_leftIconBenefitBlock_mob'/>
                </div>
                <div className='cardYourBenefit_mob cardBlue'>
                    <div className='smlCardYourBenefit_mob'>
                        <div className='headerCardYourBenefit_mob'>
                            Ваша выгода:
                        </div>
                        <div className='digValSmlCardBenefitBlock_mob success' style={{'marginLeft': '16px', marginTop: '16px'}}>
                            <div className='digVal1SmlCardBenefitBlock_mob'>
                                1.1
                            </div>
                            <div className='digVal2SmlCardBenefitBlock_mob'>
                                млн ₽
                            </div>
                        </div>
                        <div className='digTextSmlCardBenefitBlock_mob' style={{'marginLeft': '16px'}}>
                            Оборудование
                        </div>
                        <div className='digValSmlCardBenefitBlock_mob emphasized' style={{'marginLeft': '16px', marginTop: '8px'}}>
                            <div className='digVal1SmlCardBenefitBlock_mob'>
                                1.08
                            </div>
                            <div className='digVal2SmlCardBenefitBlock_mob'>
                                млн ₽
                            </div>
                        </div>
                        <div className='digTextSmlCardBenefitBlock_mob' style={{'marginLeft': '16px'}}>
                            Обслуживание за год
                        </div>
                        <div className='youCan_mob'>
                            Вы можете экономить от <span className='emphasized'>50%</span> на оборудовании и от <span className='emphasized'>70%</span> на обслуживании!
                        </div>
                        <div className='btnCardYourBenefit_mob'>
                            <BtnDefolt color={'#6486FB'} text_color={'#FFFFFF'} hover_color={'#5179fc'} text={'Оставить заявку'} height={'40px'} width={'160px'} onClick={tryFree}/>
                        </div>
                    </div>
                    <img src={car_mob} alt='' className='car_mobIconBenefitBlock_mob'/>
                </div>
            </div>
        </div>
        }
        </>
    )
}