import React from 'react';

import fonMarket from '../icons/fonMarket.png';
import fonMarket_mob from '../icons/fonMarket_mob.png';
import phonesMarket from '../icons/phonesMarket.png';
import './style.css'

export function MarketBlock({ size }) {

    return (
        <>
        {size === 'BIG'
        ?
            <div className='MarketBlock'>
                <img src={fonMarket} alt='' className='fonMarketBlock'/>
                <div className='bodyMarketBlock'>
                    <div className='titleMarketBlock'>
                        Рынок оказался свободным
                    </div>
                    <div className='rowMarketBlock'>
                        <div className='card1MarketBlock'>
                            <img src={phonesMarket} alt='' className='phonesImgMarketBlock'/>
                        </div>
                        <div className='card2MarketBlock'>
                            <div className='titleCardMarketBlock emphasized'>
                                Изменение на рынке
                            </div>
                            <div className='textCardMarketBlock'>
                                С начала СВО ушел монополист рынка GPS-мониторинга — Wialon. Компания переместилась в прибалтику и перестала напрямую работать в РФ
                            </div>
                            <div className='borderCardMarketBlock'>
                            </div>
                            <div className='titleCardMarketBlock emphasized'>
                                Наш продукт
                            </div>
                            <div className='textCardMarketBlock'>
                                Мы потратили много времени на разработку качественного IT-продукта. Получили бесценный опыт выстраивания всех бизнес-процессов, и теперь вместе с нашими партнерами активно масштабируемся на регионы
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        :
            <div className='MarketBlock_mob'>
                <img src={fonMarket_mob} alt='' className='fonMarketBlock_mob'/>
                <div className='bodyMarketBlock_mob'>
                    <div className='titleMarketBlock_mob'>
                        Рынок оказался свободным
                    </div>
                    <div className='rowMarketBlock_mob'>
                        <div className='card2MarketBlock_mob'>
                            <div className='titleCardMarketBlock_mob emphasized'>
                                Изменение на рынке
                            </div>
                            <div className='textCardMarketBlock_mob'>
                                С начала СВО ушел монополист рынка GPS-мониторинга — Wialon. Компания переместилась в прибалтику и перестала напрямую работать в РФ
                            </div>
                            <div className='borderCardMarketBlock_mob'>
                            </div>
                            <div className='titleCardMarketBlock_mob emphasized'>
                                Наш продукт
                            </div>
                            <div className='textCardMarketBlock_mob'>
                                Мы потратили много времени на разработку качественного IT-продукта. Получили бесценный опыт выстраивания всех бизнес-процессов, и теперь вместе с нашими партнерами активно масштабируемся на регионы
                            </div>
                        </div>
                        <div className='card1MarketBlock_mob'>
                            <img src={phonesMarket} alt='' className='phonesImgMarketBlock_mob'/>
                        </div>
                    </div>
                </div>
            </div>
        }
        </>
    )
}