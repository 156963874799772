import React, {useState} from 'react';

import ClickAwayListener from '@mui/material/ClickAwayListener';
import { Transition } from 'react-transition-group';

import logo from '../../../styles/icons/logo.svg';
import logo_mob from '../../../styles/icons/logo_mob.svg';
import phone from '../../../styles/icons/phone.svg';
import phone_mob from '../../../styles/icons/phone_mob.svg';
import user from '../../../styles/icons/user.svg';
import menu_mob from '../../../styles/icons/menu_mob.svg';

import './style.css'

export function TopBarBlock({size, whyWe, advantages, benefits}) {

    const [openMenu, setOpenMenu] = useState(false);

    const duration = 100;

    const defaultStyle = {
        transition: `opacity ${duration}ms ease-in-out`,
        opacity: 0,
        display: 'none'
    }

    const transitionStyles = {
        entering: { opacity: 1, display: 'block' },
        entered:  { opacity: 1, display: 'block' },
        exiting:  { opacity: 0, display: 'block' },
        exited:  { opacity: 0, display: 'none' },
    };


    const login = () => {
        document.location.href = "http://crm.controlgps.org/";
    }

    return (
        <>
        {size === 'BIG'
        ?
            <div className='TopBarBlock'>
                <div className='navbar'>
                    <img src={logo} alt='' className='logoNavbar'/>
                    <div className='textNavbar' onClick={whyWe}>
                        Почему мы
                    </div>
                    <div className='textNavbar' onClick={advantages}>
                        Преимущества
                    </div>
                    <div className='textNavbar' onClick={benefits}>
                        Выгода
                    </div>
                    <div className='phoneAndLogin'>
                        <div className='phoneNavbar'>
                            <img src={phone} alt='' className='phoneIconNavbar'/>
                            <a href="tel:+7(495)215-07-15" className='telNavbar'>
                                +7 (495) 215-07-15
                            </a>
                        </div>
                        <div className='loginNavbar'>
                            <img src={user} alt='' className='userIconNavbar'/>
                            <div className='loginTextNavbar' onClick={login}>
                                Войти в систему
                            </div>
                        </div>
                    </div>
                </div>                                     
            </div> 
        :
            <div className='TopBarBlock'>
                <div className='navbar_mob'>
                    <img src={logo_mob} alt='' className='logoNavbar_mob' />
                    <div className='phoneNavbar_mob'>
                        <img src={phone_mob} alt='' className='phoneIconNavbar_mob' />
                        <a href="tel:+7(495)215-07-15" className='telNavbar_mob'>
                            +7 (495) 215-07-15
                        </a>
                    </div>
                    <div className='menuNavbar'>
                        <ClickAwayListener onClickAway={() => setOpenMenu(false)}>
                            <div className='iconAndBodyMenuNavbar'>
                                <img src={menu_mob} className='iconMenuNavbar' onClick={() => setOpenMenu(!openMenu)} alt=''/>
                                <Transition in={openMenu} timeout={duration}>
                                    {state => (
                                        <div style={{
                                        ...defaultStyle,
                                        ...transitionStyles[state]
                                        }} className="menuBodyNavbar">
                                            <div className='textMenuBodyNavbar' onClick={whyWe}>
                                                Почему мы
                                            </div>
                                            <div className='textMenuBodyNavbar' onClick={advantages}>
                                                Преимущества
                                            </div>
                                            <div className='textMenuBodyNavbar' onClick={benefits}>
                                                Выгода
                                            </div>
                                            <div className='textMenuBodyNavbar' onClick={login}>
                                                Войти в систему
                                            </div>
                                        </div>  
                                    )}
                                </Transition>     
                            </div>
                        </ClickAwayListener>
                    </div>
                </div>                                     
            </div>
        }
        </>
    )
}