import React from 'react';
import logo_footer from '../../../styles/icons/logo_footer.svg';
import logo_footer_mob from '../../../styles/icons/logo_footer_mob.svg';
import phone_footer from '../../../styles/icons/phone_footer.svg';
import phone_footer_mob from '../../../styles/icons/phone_footer_mob.svg';

import './style.css'

export function BottomBarBlock({size}) {
    const go_privacy = () => {
        if (process.env.REACT_APP_SERVER == 'PROD') {
            window.location.href = "http://crm.controlgps.org/privacy_policy";
        } else {
            window.location.href = "http://dev.crm.controlgps.org/privacy_policy";
        }
    }

    return (
        <>
        {size === 'BIG'
        ?
            <div className='BottomBarBlock'>
                <div className='bodyBottomBarBlock'>
                    <div>
                        <div className='otdelBottomBarBlock'>
                            Отдел продаж:
                        </div>
                        <div className='phoneBottomBarBlock'>
                            <img src={phone_footer} alt="" />
                            <a href="tel:+7(495)215-07-15" className='phoneABottomBarBlock'>
                                +7 (495) 215-07-15
                            </a>
                        </div>
                        <div className='periodBottomBarBlock'>
                            Часы работы: 9:00 - 18:00 МСК
                        </div>
                    </div>
                    <div style={{ marginRight: '32px' }}>
                        <div className='logoBottomBarBlock'>
                            <img src={logo_footer} alt=''/>
                        </div>
                        <a href="https://crm.controlgps.org/privacy_policy" className='politBottomBarBlock' target="_blank">Политика конфиденциальности</a>
                        <div className='ofBottomBarBlock'>
                            ООО "Контрол Девелопмент"
                        </div>
                        <div className='ofBottomBarBlock'>
                            ОРГН 1237700601380 ИНН 7751266590
                        </div>
                    </div>
                </div>
            </div> 
        :
            <div className='BottomBarBlock_mob'>
                <div className='bodyBottomBarBlock_mob'>
                    <div className='logoBottomBarBlock_mob'>
                        <img src={logo_footer_mob} alt=''/>
                    </div>
                    <div className='otdelBottomBarBlock_mob'>
                        Отдел продаж:
                    </div>
                    <div className='phoneBottomBarBlock_mob'>
                        <img src={phone_footer_mob} alt="" height='20px' width='20px'/>
                        <a href="tel:+7(495)215-07-15" className='phoneABottomBarBlock'>
                            +7 (495) 215-07-15
                        </a>
                    </div>
                    <div className='periodBottomBarBlock_mob'>
                        Часы работы: 9:00 - 18:00 МСК
                    </div>
                    <a href="https://crm.controlgps.org/privacy_policy" className='politBottomBarBlock_mob' target="_blank">Политика конфиденциальности</a>
                    <div className='ofBottomBarBlock_mob'>
                        ООО "Контрол Девелопмент"
                    </div>
                    <div className='ofBottomBarBlock_mob'>
                        ОГРН 1237700601380 ИНН 7751266590
                    </div>
                </div>          
            </div>
        }
        </>
    )
}