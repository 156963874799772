import axios from "axios";

let base_url;

if (process.env.REACT_APP_SERVER == 'PROD') {
    base_url = 'https://api.controlgps.org/api/v2/'
} else {
    base_url = 'https://dev.api.controlgps.org/api/v2/'
}


export default class axios_api {

    static async get_parks(){

        const headers = {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
        }

        let url = base_url + 'landing/partner_list/'

        let response = await axios.get(url, { headers })

        console.log('response get_parks : ', response)

        return response
    }

    static async get_cars_count(){

        const headers = {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
        }

        let url = base_url + 'landing/car_count/'

        let response = await axios.get(url, { headers })

        console.log('response get_cars_count : ', response)

        return response
    }

    static async post_clients(name, phone){

        let body = { 
            'name': name,
            'phone': phone
        }

        const headers = {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
        }

        let url = base_url + 'clients/'

        let response = await axios.post(url, body, {
            headers: headers,
        })

        console.log('response post_clients : ', response)

        return response
    }
}