import React, {useState, useRef, useEffect} from 'react';
import ReactPlayer from 'react-player'
import './style.css'

export function Card({info, size, educationBaseState, setEducationBaseState, render, setRender}) {

    const ref = useRef(null)
    const [type, setType] = useState('BIG')
    const [timer, setTimer] = useState(true)

    const onPlayVideoBig = () => {
        for (let card of educationBaseState) {
            if (info.id !== card.id) {
                card.playingBIG = false
                card.playingSML = false
            } else {
                card.playingBIG = true
                card.playingSML = false
            }
        }
        setEducationBaseState(educationBaseState)
        setRender(render + 1)
    }

    const onPlayVideoSml = () => {
        for (let card of educationBaseState) {
            if (info.id !== card.id) {
                card.playingBIG = false
                card.playingSML = false
            } else {
                card.playingBIG = false
                card.playingSML = true
            }
        }
        setEducationBaseState(educationBaseState)
        setRender(render + 1)
    }

    useEffect(() => {
        for (let card of educationBaseState) {
            if (info.id === card.id) {
                card.ref = ref
            }
        }
        setEducationBaseState(educationBaseState)
        setRender(render + 1)
        // setTimeout(() => {
        //     setTimer(true)
        // }, 500);
    }, [])

    return (
        <div className={'DivEducationCard' + size} key={info.id} ref={ref}>
            <div className={'EducationCard' + size}>
                <div className={'QuestionEducationCard' + size}>
                    {info.question}
                </div>
                <div className={'BodyEducationCard' + size}>
                    <div className={'HeaderEducationCard' + size}>
                        <div className={type === 'BIG' ? 'BigValueHeaderEducationCard' + size + ' active emphasized' : 'BigValueHeaderEducationCard' + size} onClick={() => setType('BIG')}>
                            Для компьютера
                        </div>
                        <div className={type === 'BIG' ? 'MobValueHeaderEducationCard' + size : 'MobValueHeaderEducationCard' + size + ' active emphasized'} onClick={() => setType('SML')}>
                            Для телефона
                        </div>
                    </div>
                    <div className={'TextEducationCard' + size}>
                        {type === 'BIG' ? info.textBIG : info.textSML}
                    </div>
                    <div className={'VideoEducationCard' + size}>
                    {timer 
                    ?
                        <>
                        {type === 'BIG' ?                                 
                            <ReactPlayer 
                                url={info.urlBIG}
                                controls={true} 
                                playing={info.playingBIG}
                                height={size === 'BIG' ? '320px' : '157px'}
                                width={size === 'BIG' ? '568px' : '280px'}
                                onPlay={onPlayVideoBig}
                            /> 
                            :
                            <ReactPlayer 
                                url={info.urlSML}
                                controls={true} 
                                playing={info.playingSML}
                                height={size === 'BIG' ? '320px' : '157px'}
                                width={size === 'BIG' ? '568px' : '280px'} 
                                onPlay={onPlayVideoSml}
                            />
                        }
                        </>
                    :
                        <>
                        {size === 'BIG' 
                        ?
                            <div style={{height: '320px', width: '568px'}}></div>
                        :
                            <div style={{height: '157px', width: '280px'}}></div>
                        }
                        </>
                    }
                    </div>
                </div>
            </div>
        </div>
    )
}