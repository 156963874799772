import React from 'react';
import '../../styles/components/components.css'

export default function Basic_input(props) {

    let input_class
    let text_class
    let input_readonly = false
    
    check_status()

    return (
        <div className='div_basic_input' style={{'width': props.width}}>
            <input value={props.value} onChange={e => props.onChange(e.target.value)} className={input_class} readOnly={input_readonly} type={props.type}/>
            <div className={text_class}>
                {props.text}
            </div>
        </div>
    )

    function check_status(){
        if (props.status === 'active'){
            input_class = 'input_basic_input'
            text_class = 'text_basic_input'
        }
        if (props.status === 'readonly'){
            input_readonly = true
            input_class = 'input_basic_input readonly'
        }
        if (props.status === 'error'){
            input_class = 'input_basic_input error'
            text_class = 'text_basic_input error'
        }
        if (props.status === 'success'){
            input_class = 'input_basic_input success'
            text_class = 'text_basic_input success'
        }
    }
}