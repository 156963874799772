import React from 'react';

import bigCar from '../icons/bigCar.png';
import bigCar_mob from '../icons/bigCar_mob.png';
import grafStart from '../icons/grafStart.png';
import grafIncome from '../icons/grafIncome.png';
import './style.css'

export function EconomyBlock({ size, economyRef, earningsRef }) {

    return (
        <>
        {size === 'BIG'
        ?
            <div className='EconomyBlock' ref={economyRef}>
                <div className='bodyEconomyBlock'>
                    <div className='titleEconomyBlock'>
                        Экономика
                    </div>
                    <div className='cardEconomyBlock'>
                        <div className='textCardEconomyBlock white'>
                            На каждый областной центр приходится в среднем 5-8 авто такси на 1000 населения и порядка 100 таксопарков.
                        </div>
                        <div className='textCardEconomyBlock white'>
                            Вам достаточно один раз подключить клиентов — дальше ваш бизнес зарабатывает за счет абонентской платы и заказов от уже работающих с вами таксопарков, которые регулярно обновляют парк авто.
                        </div>
                        <img src={bigCar} alt='' className='imgCardEconomyBlock'/>
                    </div>
                    <div className='rowEconomyBlock' ref={earningsRef}>
                        <div className='colEconomyBlock'>
                            <div className='titleColEconomyBlock'>
                                Запуск
                            </div>
                            <div className='cardColEconomyBlock'>
                                <div className='titleCardColEconomyBlock emphasized'>
                                    500 ТЫС ₽
                                </div>
                                <div className='textCardColEconomyBlock'>
                                    Необходимые вложения для запуска
                                </div>
                            </div>
                            <div className='pointColEconomyBlock'>
                                Расходы на один центр:
                            </div>
                            <div className='cardColEconomyBlock'>
                                <div className='titleCardColEconomyBlock'>
                                    60 000 – 80 000 ₽
                                </div>
                                <div className='textCardColEconomyBlock'>
                                    Зарплата менеджера по продажам
                                </div>
                            </div>
                            <div className='divBorderSmallColEconomyBlock'>
                                <div className='borderSmallColEconomyBlock'>
                                </div>
                            </div>
                            <div className='cardColEconomyBlock'>
                                <div className='titleCardColEconomyBlock'>
                                    60 000 – 80 000 ₽
                                </div>
                                <div className='textCardColEconomyBlock'>
                                    Зарплата автоэлектрика
                                </div>
                            </div>
                            <div className='divBorderBigColEconomyBlock'>
                                <div className='borderBigColEconomyBlock'>
                                </div>
                            </div>
                            <div className='grafCardColEconomyBlock greyCard'>
                                <div className='bodyGrafCardColEconomyBlock'>
                                    <div>
                                        <div className='titleGrafCardColEconomyBlock'>
                                            Итог в месяц
                                        </div>
                                        <div className='valueGrafCardColEconomyBlock emphasized'>
                                            120 - 160 ТЫС ₽
                                        </div>
                                    </div>
                                    <img src={grafStart} alt='' className='imgGrafCardColEconomyBlock'/>
                                </div>
                            </div>
                        </div>
                        <div className='midBorderRowEconomyBlock'>
                        </div>
                        <div className='colEconomyBlock'>
                            <div className='titleColEconomyBlock'>
                                Доход
                            </div>
                            <div className='cardColEconomyBlock blueCard white'>
                                <div className='titleCardColEconomyBlock'>
                                    При 3000 авто
                                </div>
                                <div className='textCardColEconomyBlock'>
                                    20% рынка среднего региона
                                </div>
                            </div>
                            <div className='pointColEconomyBlock'>
                                Доход на один центр:
                            </div>
                            <div className='cardColEconomyBlock blueCard white'>
                                <div className='titleCardColEconomyBlock'>
                                    до 1 200 000 ₽
                                </div>
                                <div className='textCardColEconomyBlock'>
                                    В месяц с абонентской платы
                                </div>
                            </div>
                            <div className='divBorderSmallColEconomyBlock'>
                                <div className='borderSmallColEconomyBlock'>
                                </div>
                            </div>
                            <div className='cardColEconomyBlock blueCard white'>
                                <div className='titleCardColEconomyBlock'>
                                    до 300 000 ₽
                                </div>
                                <div className='textCardColEconomyBlock'>
                                    В месяц на продаже оборудования
                                </div>
                            </div>
                            <div className='divBorderBigColEconomyBlock'>
                                <div className='borderBigColEconomyBlock'>
                                </div>
                            </div>
                            <div className='grafCardColEconomyBlock blueCard'>
                                <div className='bodyGrafCardColEconomyBlock'>
                                    <div>
                                        <div className='titleGrafCardColEconomyBlock'>
                                            Итог в месяц
                                        </div>
                                        <div className='valueGrafCardColEconomyBlock success'>
                                            до 1 500 000 МЛН ₽
                                        </div>
                                    </div>
                                    <img src={grafIncome} alt='' className='imgGrafCardColEconomyBlock'/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        :
            <div className='EconomyBlock_mob' ref={economyRef}>
                <div className='bodyEconomyBlock_mob'>
                    <div className='titleEconomyBlock_mob'>
                        Экономика
                    </div>
                    <div className='cardEconomyBlock_mob'>
                        <div className='textCardEconomyBlock_mob white'>
                            На каждый областной центр приходится в среднем 5-8 авто такси на 1000 населения и порядка 100 таксопарков.
                        </div>
                        <div className='textCardEconomyBlock_mob white'>
                            Вам достаточно один раз подключить клиентов — дальше ваш бизнес зарабатывает за счет абонентской платы и заказов от уже работающих с вами таксопарков, которые регулярно обновляют парк авто.
                        </div>
                        <img src={bigCar_mob} alt='' className='imgCardEconomyBlock_mob'/>
                    </div>
                    <div className='rowEconomyBlock_mob' ref={earningsRef}>
                        <div className='colEconomyBlock_mob'>
                            <div className='titleColEconomyBlock_mob'>
                                Запуск
                            </div>
                            <div className='cardColEconomyBlock_mob'>
                                <div className='titleCardColEconomyBlock_mob emphasized'>
                                    500 ТЫС ₽
                                </div>
                                <div className='textCardColEconomyBlock_mob'>
                                    Необходимые вложения для запуска
                                </div>
                            </div>
                            <div className='pointColEconomyBlock_mob'>
                                Расходы на один центр:
                            </div>
                            <div className='cardColEconomyBlock_mob'>
                                <div className='titleCardColEconomyBlock_mob'>
                                    60 000 – 80 000 ₽
                                </div>
                                <div className='textCardColEconomyBlock_mob'>
                                    Зарплата менеджера по продажам
                                </div>
                            </div>
                            <div className='divBorderSmallColEconomyBlock_mob'>
                                <div className='borderSmallColEconomyBlock_mob'>
                                </div>
                            </div>
                            <div className='cardColEconomyBlock_mob'>
                                <div className='titleCardColEconomyBlock_mob'>
                                    60 000 – 80 000 ₽
                                </div>
                                <div className='textCardColEconomyBlock_mob'>
                                    Зарплата автоэлектрика
                                </div>
                            </div>
                            <div className='divBorderBigColEconomyBlock_mob'>
                                <div className='borderBigColEconomyBlock_mob'>
                                </div>
                            </div>
                            <div className='grafCardColEconomyBlock_mob greyCard'>
                                <div className='bodyGrafCardColEconomyBlock_mob'>
                                    <div className='divTextGrafCardColEconomyBlock_mob'>
                                        <div className='titleGrafCardColEconomyBlock_mob'>
                                            Итог в месяц
                                        </div>
                                        <div className='valueGrafCardColEconomyBlock_mob emphasized'>
                                            120 - 160 ТЫС ₽
                                        </div>
                                    </div>
                                    <img src={grafStart} alt='' className='imgGrafCardColEconomyBlock_mob'/>
                                </div>
                            </div>
                        </div>
                        <div className='colEconomyBlock_mob'>
                            <div className='titleColEconomyBlock_mob'>
                                Доход
                            </div>
                            <div className='cardColEconomyBlock_mob blueCard white'>
                                <div className='titleCardColEconomyBlock_mob'>
                                    При 3000 авто
                                </div>
                                <div className='textCardColEconomyBlock_mob'>
                                    20% рынка среднего региона
                                </div>
                            </div>
                            <div className='pointColEconomyBlock_mob'>
                                Доход на один центр:
                            </div>
                            <div className='cardColEconomyBlock_mob blueCard white'>
                                <div className='titleCardColEconomyBlock_mob'>
                                    до 1 200 000 ₽
                                </div>
                                <div className='textCardColEconomyBlock_mob'>
                                    В месяц с абонентской платы
                                </div>
                            </div>
                            <div className='divBorderSmallColEconomyBlock_mob'>
                                <div className='borderSmallColEconomyBlock_mob'>
                                </div>
                            </div>
                            <div className='cardColEconomyBlock_mob blueCard white'>
                                <div className='titleCardColEconomyBlock_mob'>
                                    до 300 000 ₽
                                </div>
                                <div className='textCardColEconomyBlock_mob'>
                                    В месяц на продаже оборудования
                                </div>
                            </div>
                            <div className='divBorderBigColEconomyBlock_mob'>
                                <div className='borderBigColEconomyBlock_mob'>
                                </div>
                            </div>
                            <div className='grafCardColEconomyBlock_mob blueCard'>
                                <div className='bodyGrafCardColEconomyBlock_mob'>
                                    <div className='divTextGrafCardColEconomyBlock_mob'>
                                        <div className='titleGrafCardColEconomyBlock_mob'>
                                            Итог в месяц
                                        </div>
                                        <div className='valueGrafCardColEconomyBlock_mob success'>
                                            до 1 500 000 МЛН ₽
                                        </div>
                                    </div>
                                    <img src={grafIncome} alt='' className='imgGrafCardColEconomyBlock_mob'/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        }
        </>
    )
}