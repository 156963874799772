import React from 'react';
import appstore from '../../../styles/icons/appstore.svg';
import googleplay from '../../../styles/icons/googleplay.svg';
import appstore_mob from '../../../styles/icons/appstore_mob.svg';
import googleplay_mob from '../../../styles/icons/googleplay_mob.svg';
import group from '../../../styles/icons/group.png';
import group_mob from '../../../styles/icons/group_mob.png';
import control from '../../../styles/icons/control.png';
import udobstvo from '../../../styles/icons/udobstvo.png';
import dot from '../../../styles/icons/dot.svg';
import control_mob from '../../../styles/icons/control_mob.png';
import udobstvo_mob from '../../../styles/icons/udobstvo_mob.png';
import dot_mob from '../../../styles/icons/dot_mob.svg';
import image_phone_1 from '../../../styles/icons/image_phone_1.png';
import image_phone_2 from '../../../styles/icons/image_phone_2.png';
import image_phone_3 from '../../../styles/icons/image_phone_3.png';
import image_phone_4 from '../../../styles/icons/image_phone_4.png';
import image_phones_mob from '../../../styles/icons/image_phones_mob.png';

import BtnDefolt from '../../../components/UI/btn_defolt.jsx'
import './style.css'

export function OurDevelopmentBlock({ size, tryFree, advantagesRef }) {

    return (
        <>
        {size === 'BIG'
        ?
        <div className='OurDevelopmentBlock'>
            <div className='bodyOurDevelopmentBlock'>
                <div className='groupOurDevelopmentBlock' ref={advantagesRef}>
                    <div className='columnOurDevelopmentBlock'>
                        <div className='cardOurDevelopmentBlock' style={{ minHeight: '482px', justifyContent: 'center' }}>
                            <div className='weCreate'>
                                <span className='emphasized'>Мы разработали ControlGPS</span> — уникальное решение для таксопарков
                            </div>
                            <div className='weCreate2'>
                                Благодаря нашей системе, вы можете следить за вашими авто с любого устройства, отправлять команды глушения двигателя. Мы поддерживаем интеграцию с <span className='emphasized'>Яндекс Такси</span>, и предоставляем возможность <span className='emphasized'>автоматически глушить двигатели должникам</span> по их балансу в Яндекс!
                            </div>
                            <div className='iconsApps'>
                                <a className='appstoreIconOurDevelopmentBlock' href="https://apps.apple.com/ru/app/controlgps/id6444350023?l=ru" target={'_blank' }>
                                    <img src={appstore} alt='' />                    
                                </a>
                                <a className='googleplayIconOurDevelopmentBlock' href="https://play.google.com/store/apps/details?id=org.controlgps&pli=1" target={'_blank'}>
                                    <img src={googleplay} alt='' />
                                </a>
                                {/* <img src={appstore} alt='' className='appstoreIconOurDevelopmentBlock' />
                                <img src={googleplay} alt='' className='googleplayIconOurDevelopmentBlock' /> */}
                            </div>
                        </div>
                        <div className='cardOurDevelopmentBlock' style={{ justifyContent: 'center' }}>
                            <img src={group} alt='' className='groupIconOurDevelopmentBlock'/>
                        </div>
                    </div>
                    <div className='columnOurDevelopmentBlock'>
                        <div className='cardOurDevelopmentBlock filledCard' style={{ marginTop: '32px' }}>
                            <div className='cardHeaderOurDevelopmentBlock'>
                                Контроль
                            </div>
                            <div className='cardItemOurDevelopmentBlock'>
                                <img src={dot} alt='' className='dotIconOurDevelopmentBlock' />
                                <div>
                                    Отправление команд на трекер без СМС
                                </div>
                            </div>
                            <div className='cardItemOurDevelopmentBlock'>
                                <img src={dot} alt='' className='dotIconOurDevelopmentBlock' />
                                <div>
                                    Контроль за сливами топлива из автомобиля
                                </div>
                            </div>
                            <div className='cardItemOurDevelopmentBlock'>
                                <img src={dot} alt='' className='dotIconOurDevelopmentBlock' />
                                <div>
                                    <span className='emphasized'>Контроль выезда из геозоны</span> с оповещениями на телефон
                                </div>
                            </div>
                            <div className='cardItemOurDevelopmentBlock'>
                                <img src={dot} alt='' className='dotIconOurDevelopmentBlock' />
                                <div>
                                    История перемещений ваших авто за любой период: <span className='emphasized'>полный контроль за вашим автопарком</span>
                                </div>
                            </div>
                            <img src={control} alt='' className='controlIconOurDevelopmentBlock' />
                        </div>
                        <div className='cardOurDevelopmentBlock filledCard' style={{ marginTop: '32px' }}>
                            <div className='cardHeaderOurDevelopmentBlock'>
                                Удобство
                            </div>
                            <div className='cardItemOurDevelopmentBlock'>
                                <img src={dot} alt='' className='dotIconOurDevelopmentBlock' />
                                <div>
                                    Автоматические пополнения симкарт маяков без необходимости следить за их балансом
                                </div>
                            </div>
                            <div className='cardItemOurDevelopmentBlock'>
                                <img src={dot} alt='' className='dotIconOurDevelopmentBlock' />
                                <div>
                                    Оповещения о длительной неактивности трекера: неактивные маяки не нужно искать самостоятельно
                                </div>
                            </div>
                            <div className='cardItemOurDevelopmentBlock'>
                                <img src={dot} alt='' className='dotIconOurDevelopmentBlock' />
                                <div>
                                    Моментальные уведомления об отключении трекера от питания для исключения возможности угона авто
                                </div>
                            </div>
                            <img src={udobstvo} alt='' className='controlIconOurDevelopmentBlock' />
                        </div>
                    </div>
                </div>
                <div className='footerCardOurDevelopmentBlock'>
                    <div className='footerBodyCardOurDevelopmentBlock'>
                        <div className='goRuleFooterCard'>
                            Начните управлять Вашим автопарком прямо сейчас 
                        </div>
                        <div className='distanceFooterCard'>
                            Не нужно простаивать в автомастерских и ждать очередь — подключение вашего оборудования к системе происходи дистанционно
                        </div>
                        <div className='btnCardOurDevelopmentBlock'>
                            <BtnDefolt color={'#6486FB'} text_color={'#FFFFFF'} hover_color={'#5179fc'} text={'Попробовать бесплатно'} height={'50px'} width={'250px'} onClick={tryFree}/>
                            <div className='muted periodFooterCardOurDevelopmentBlock'>
                                *Пробный период длится 7 дней
                            </div>
                        </div>
                        <img src={image_phone_1} alt='' className='p1IconOurDevelopmentBlock' />
                        <img src={image_phone_2} alt='' className='p2IconOurDevelopmentBlock' />
                        <img src={image_phone_3} alt='' className='p3IconOurDevelopmentBlock' />
                        <img src={image_phone_4} alt='' className='p4IconOurDevelopmentBlock' />
                    </div>
                </div>
            </div>    
        </div>
        :
        <div className='OurDevelopmentBlock_mob'>
            <div className='bodyOurDevelopmentBlock_mob'>
                <div className='cardOurDevelopmentBlock_mob' ref={advantagesRef}>
                    <div className='weCreate_mob'>
                        <span className='emphasized'>Мы разработали ControlGPS</span> — уникальное решение для таксопарков
                    </div>
                    <div className='weCreate2_mob'>
                        Благодаря нашей системе, вы можете следить за вашими авто с любого устройства, отправлять команды глушения двигателя. Мы поддерживаем интеграцию с <span className='emphasized'>Яндекс Такси</span>, и предоставляем возможность <span className='emphasized'>автоматически глушить двигатели должникам</span> по их балансу в Яндекс!
                    </div>
                    <div className='iconsApps_mob'>
                        <a className='appstoreIconOurDevelopmentBlock_mob' href="https://apps.apple.com/ru/app/controlgps/id6444350023?l=ru" target={'_blank' }>
                            <img src={appstore_mob} alt='' />                    
                        </a>
                        <a className='googleplayIconOurDevelopmentBlock_mob' href="https://play.google.com/store/apps/details?id=org.controlgps&pli=1" target={'_blank'}>
                            <img src={googleplay_mob} alt='' className='' />
                        </a>
                    </div>
                </div>
                <div className='cardOurDevelopmentBlock_mob'>
                    <img src={group_mob} alt='' className='groupIconOurDevelopmentBlock_mob' />
                </div>
                <div className='cardOurDevelopmentBlock_mob filledCard_mob'>
                    <div className='cardHeaderOurDevelopmentBlock_mob'>
                        Контроль
                    </div>
                    <div className='cardItemOurDevelopmentBlock_mob'>
                        <img src={dot_mob} alt='' className='dotIconOurDevelopmentBlock_mob' />
                        <div>
                            Отправление команд на трекер без СМС
                        </div>
                    </div>
                    <div className='cardItemOurDevelopmentBlock_mob'>
                        <img src={dot_mob} alt='' className='dotIconOurDevelopmentBlock_mob' />
                        <div>
                            Контроль за сливами топлива из автомобиля
                        </div>
                    </div>
                    <div className='cardItemOurDevelopmentBlock_mob'>
                        <img src={dot_mob} alt='' className='dotIconOurDevelopmentBlock_mob' />
                        <div>
                            <span className='emphasized'>Контроль выезда из геозоны</span> с оповещениями на телефон
                        </div>
                    </div>
                    <div className='cardItemOurDevelopmentBlock_mob'>
                        <img src={dot_mob} alt='' className='dotIconOurDevelopmentBlock_mob' />
                        <div>
                            История перемещений ваших авто за любой период: <span className='emphasized'>полный контроль за вашим автопарком</span>
                        </div>
                    </div>
                    <img src={control_mob} alt='' className='controlIconOurDevelopmentBlock_mob' />
                </div>
                <div className='cardOurDevelopmentBlock_mob filledCard_mob'>
                    <div className='cardHeaderOurDevelopmentBlock_mob'>
                        Удобство
                    </div>
                    <div className='cardItemOurDevelopmentBlock_mob'>
                        <img src={dot_mob} alt='' className='dotIconOurDevelopmentBlock_mob' />
                        <div>
                            Автоматические пополнения симкарт маяков без необходимости следить за их балансом
                        </div>
                    </div>
                    <div className='cardItemOurDevelopmentBlock_mob'>
                        <img src={dot_mob} alt='' className='dotIconOurDevelopmentBlock_mob' />
                        <div>
                            Оповещения о длительной неактивности трекера: неактивные маяки не нужно искать самостоятельно
                        </div>
                    </div>
                    <div className='cardItemOurDevelopmentBlock_mob'>
                        <img src={dot_mob} alt='' className='dotIconOurDevelopmentBlock_mob' />
                        <div>
                            Моментальные уведомления об отключении трекера от питания для исключения возможности угона авто
                        </div>
                    </div>
                    <img src={udobstvo_mob} alt='' className='controlIconOurDevelopmentBlock_mob' />
                </div>
                <div className='footerCardOurDevelopmentBlock_mob'>
                    <div className='footerBodyCardOurDevelopmentBlock_mob'>
                        <div className='goRuleFooterCard_mob'>
                            Начните управлять Вашим автопарком прямо сейчас 
                        </div>
                        <div className='distanceFooterCard_mob'>
                            Не нужно простаивать в автомастерских и ждать очередь — подключение вашего оборудования к системе происходи дистанционно
                        </div>
                        <div className='btnCardOurDevelopmentBlock_mob'>
                            <BtnDefolt color={'#6486FB'} text_color={'#FFFFFF'} hover_color={'#5179fc'} text={'Попробовать бесплатно'} height={'40px'} width={'200px'} onClick={tryFree}/>
                            <div className='muted periodFooterCardOurDevelopmentBlock_mob'>
                                *Пробный период длится 7 дней
                            </div>
                        </div>
                        {/* <img src={car_OLD} alt='' className='' /> */}
                        {/* <i src={image_phones_mob} alt='' className='pIconOurDevelopmentBlock_mob_TEST' /> */}
                        <img src={image_phones_mob} alt='' className='pIconOurDevelopmentBlock_mob' />
                    </div>
                </div>
            </div>    
        </div>
        }
        </>
    )
}