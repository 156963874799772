import React, { useEffect, useState } from 'react';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import BtnArrow from '../../../components/UI/btn_arrow_for_settings'
import arrow_right_white_icon from '../../../styles/icons/arrow_right_white_icon.svg';
import arrow_left_white_icon from '../../../styles/icons/arrow_left_white_icon.svg';
import './style.css'

export function Navbar({size, educationBaseState, setEducationBaseState, render, setRender}) {

    const [open, setOpen] = useState(false)
    const [firstopen, setFirstOpen] = useState(true)
    const [btnActive, setBtnActive] = useState(false)

    useEffect(() => {
        if (document.documentElement.clientWidth > 1024){
            setTimeout(() => {
                console.log('BIG')
                setOpen(true)
                setFirstOpen(false)
            }, 100)
        } else {
            setTimeout(() => {
                console.log('SML')
                setOpen(true)
                setFirstOpen(false)
            }, 1500)
        }
    }, [])

    const onClickQuestion = (info) => {
        setOpen(false)
        info.ref.current.scrollIntoView({
            behavior: "smooth",
        });
    }

    const onClickAway = () => {
        setOpen(false)
        console.log('onClickAway')
    }

    const onMouseEnterBIG = () => {
        if (document.getElementsByClassName('OpenedDivNavbarEducationBIG')[0]) {
            document.getElementsByClassName('OpenedDivNavbarEducationBIG')[0].style.backgroundColor = 'rgba(0, 0 , 0, 0.7)'
        }
    }

    const onMouseLeaveBIG = () => {
        if (document.getElementsByClassName('OpenedDivNavbarEducationBIG')[0]) {
            document.getElementsByClassName('OpenedDivNavbarEducationBIG')[0].style.backgroundColor = 'rgba(0, 0 , 0, 0.6)'
        }
    }

    return (
        <>
        {size === 'BIG'
        ?
            <>
            {open
            ?
                <div className={'OpenedDivNavbarEducation' + size}>
                    <ClickAwayListener onClickAway={onClickAway}>
                        <div className={'OpenedNavbarEducation' + size} onMouseEnter={onMouseEnterBIG} onMouseLeave={onMouseLeaveBIG}>
                            {educationBaseState.map((info) => 
                                <div className={'ItemNavbarEducation' + size} key={info.id} onClick={() => onClickQuestion(info)}>
                                    {info.question}
                                </div>
                            )}
                            <div className={'btnNavbarEducation' + size} onMouseEnter={() => setBtnActive(true)} onMouseLeave={() => setBtnActive(false)} style={{cursor: 'pointer'}}>
                                <BtnArrow onClick={() => setOpen(!open)} active={btnActive} color={'#6486FB'} active_color={'#4d76fa'} not_active_icon={arrow_left_white_icon} active_icon={arrow_left_white_icon} height={'60px'} width={'20px'}/>
                            </div>
                        </div>
                    </ClickAwayListener>
                </div>
            :
                <>
                {!firstopen 
                ?
                    <div className={'ClosedNavbarEducation' + size} onMouseEnter={onMouseEnterBIG} onMouseLeave={onMouseLeaveBIG}>
                        {educationBaseState.map((info) => 
                            <div className={'ItemNavbarEducation' + size} key={info.id} onClick={() => onClickQuestion(info)}>
                                {info.question}
                            </div>
                        )}
                        <div className={'btnNavbarEducation' + size} onMouseEnter={() => setBtnActive(true)} onMouseLeave={() => setBtnActive(false)} style={{cursor: 'pointer'}}>
                            <BtnArrow onClick={() => setOpen(!open)} active={btnActive} color={'#6486FB'} active_color={'#4d76fa'} not_active_icon={arrow_left_white_icon} active_icon={arrow_left_white_icon} height={'60px'} width={'20px'}/>
                        </div>
                    </div>
                :
                    <></>
                }
                <div className={'NavbarEducation' + size} onClick={() => setOpen(!open)}>
                    <div className={'btnNavbarEducation' + size} onMouseEnter={() => setBtnActive(true)} onMouseLeave={() => setBtnActive(false)} style={{cursor: 'pointer'}}>
                        <BtnArrow onClick={() => setOpen(!open)} active={btnActive} color={'#6486FB'} active_color={'#4d76fa'} not_active_icon={arrow_right_white_icon} active_icon={arrow_right_white_icon} height={'60px'} width={'20px'}/>
                    </div>
                </div>
                </>
            }
            </>
        :
            <>
            {open
            ?
                <div className={'OpenedDivNavbarEducation' + size}>
                    <ClickAwayListener onClickAway={onClickAway}>
                        <div className={'OpenedNavbarEducation' + size}>
                            {educationBaseState.map((info) => 
                                <div className={'ItemNavbarEducation' + size} key={info.id} onClick={() => onClickQuestion(info)}>
                                    {info.question}
                                </div>
                            )}
                            <div className={'btnNavbarEducation' + size} onMouseEnter={() => setBtnActive(true)} onMouseLeave={() => setBtnActive(false)} style={{cursor: 'pointer'}}>
                                <BtnArrow onClick={() => setOpen(!open)} active={btnActive} color={'#6486FB'} active_color={'#4d76fa'} not_active_icon={arrow_left_white_icon} active_icon={arrow_left_white_icon} height={'60px'} width={'20px'}/>
                            </div>
                        </div>
                    </ClickAwayListener>
                </div>
            :
                <>
                {!firstopen 
                ? 
                    <div className={'ClosedNavbarEducation' + size}>
                        {educationBaseState.map((info) => 
                            <div className={'ItemNavbarEducation' + size} key={info.id} onClick={() => onClickQuestion(info)}>
                                {info.question}
                            </div>
                        )}
                        <div className={'btnNavbarEducation' + size} onMouseEnter={() => setBtnActive(true)} onMouseLeave={() => setBtnActive(false)} style={{cursor: 'pointer'}}>
                            <BtnArrow onClick={() => setOpen(!open)} active={btnActive} color={'#6486FB'} active_color={'#4d76fa'} not_active_icon={arrow_left_white_icon} active_icon={arrow_left_white_icon} height={'60px'} width={'20px'}/>
                        </div>
                    </div>
                :
                    <></>
                }
                <div className={'NavbarEducation' + size} onClick={() => setOpen(!open)}>
                    <div className={'btnNavbarEducation' + size} onMouseEnter={() => setBtnActive(true)} onMouseLeave={() => setBtnActive(false)} style={{cursor: 'pointer'}}>
                        <BtnArrow onClick={() => setOpen(!open)} active={btnActive} color={'#6486FB'} active_color={'#4d76fa'} not_active_icon={arrow_right_white_icon} active_icon={arrow_right_white_icon} height={'60px'} width={'20px'}/>
                    </div>
                </div>
                </>
            }
            </>
        }
        </>
    )
}