import React, { useEffect, useState } from 'react'

import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'

import axios_api from '../../../API/axios_api'

import BtnIcon from '../../../components/UI/icon_btn.jsx'
import arrow_right from '../../../styles/icons/arrow_right.svg'
import arrow_left from '../../../styles/icons/arrow_left.svg'

import './style.css'

// let parks = []

let parks = []

const responsive = {
  desktop_big: {
    breakpoint: { max: 5000, min: 1311 },
    items: 4,
  },
  desktop: {
    breakpoint: { max: 1310, min: 1025 },
    items: 3,
  },
  mobile_big: {
    breakpoint: { max: 1024, min: 551 },
    items: 3,
  },
  mobile_med: {
    breakpoint: { max: 550, min: 390 },
    items: 2.4,
  },
  mobile_sml: {
    breakpoint: { max: 389, min: 0 },
    items: 2.2,
  },
}

export function OurParksBlock({ size }) {
  const [renderCounter, setRenderCounter] = useState(0)
  const [carsCount, setCarsCount] = useState(0)
  const [parks, setParks] = useState([])
  const [parkCountText1, setParkCountText1] = useState('пользуется')
  const [parkCountText2, setParkCountText2] = useState('таксопарк')
  const [carsCountText1, setCarsCountText1] = useState('автомобилей')
  const [carsCountText2, setCarsCountText2] = useState('подключены')

  const refreshParks = async () => {
    let new_parks = await axios_api.get_parks()

    if (new_parks.status === 200) {
      for (let park of new_parks.data) {
        let names = park.name.split(' ')
        let new_names = []

        for (let name of names) {
          if (name.length > 10) {
            new_names.push(name.slice(0, 8) + '...')
          } else {
            new_names.push(name)
          }
        }

        console.log(new_names)

        let new_name = ''
        for (let name of new_names) {
          new_name = new_name + ' ' + name
        }
        park.name = new_name
      }

      setParks(new_parks.data)

      let lastDigit = new_parks.data.length % 10

      if (lastDigit === 1) {
        setParkCountText1('пользуется')
        setParkCountText2('таксопарк')
      }

      if (lastDigit >= 2 && lastDigit <= 4) {
        setParkCountText1('пользуются')
        setParkCountText2('таксопарка')
      }

      if (lastDigit >= 5) {
        setParkCountText1('пользуются')
        setParkCountText2('таксопарков')
      }
    }

    setRenderCounter(renderCounter + 1)
  }

  const refreshCars = async () => {
    let new_cars_count = await axios_api.get_cars_count()

    if (new_cars_count.status === 200) {
      setCarsCount(new_cars_count.data.count * 3)

      let lastDigit = (new_cars_count.data.count * 3) % 10

      if (lastDigit === 1) {
        setCarsCountText1('автомобиль')
        setCarsCountText2('подключен')
      }

      if (lastDigit >= 2 && lastDigit <= 4) {
        setCarsCountText1('автомобиля')
        setCarsCountText2('подключены')
      }

      if (lastDigit >= 5) {
        setCarsCountText1('автомобилей')
        setCarsCountText2('подключены')
      }
    }
  }

  useEffect(() => {
    refreshParks()
    refreshCars()
  }, [])

  const CustomRightArrow = ({ onClick, ...rest }) => {
    return (
      <div className="CustomRightArrow">
        <BtnIcon
          color={'#6486FB'}
          text_color={'#FFFFFF'}
          hover_color={'#5179fc'}
          icon={arrow_right}
          height={'56px'}
          width={'56px'}
          onClick={onClick}
        />
      </div>
    )
  }

  const CustomLefttArrow = ({ onClick, ...rest }) => {
    return (
      <div className="CustomLeftArrow">
        <BtnIcon
          color={'#6486FB'}
          text_color={'#FFFFFF'}
          hover_color={'#5179fc'}
          icon={arrow_left}
          height={'56px'}
          width={'56px'}
          onClick={onClick}
        />
      </div>
    )
  }

  return (
    <>
      {parks.length === 0 ? (
        <></>
      ) : (
        <>
          {size === 'BIG' ? (
            <div className="OurParksBlock">
              <div className="bodyOurParksBlock">
                <div className="headerOurParksBlock">
                  <div className="ParksOurParksBlock">
                    <div className="Parks1OurParksBlock">Нашим сервисом</div>
                    <div className="Parks2OurParksBlock emphasized">
                      {parkCountText1} {parks.length} {parkCountText2}:
                    </div>
                  </div>
                  <div className="CarsOurParksBlock">
                    <div className="Cars1OurParksBlock emphasized">
                      {carsCount} {carsCountText1}
                    </div>
                    <div className="Cars2OurParksBlock">
                      {carsCountText2} к системе на данный момент
                    </div>
                  </div>
                </div>
                <div className="CarouselOurParksBlock">
                  <Carousel
                    // swipeable={false}
                    // draggable={false}
                    // showDots={true}
                    responsive={responsive}
                    customRightArrow={<CustomRightArrow />}
                    customLeftArrow={<CustomLefttArrow />}
                    infinite={true}
                    autoPlay={true}
                    autoPlaySpeed={2000}
                  >
                    {parks.map((park) => {
                      return (
                        <div
                          className="CarouselFakeItemOurParksBlock"
                          key={park.id}
                        >
                          <div
                            key={park.id}
                            className="CarouselItemOurParksBlock"
                          >
                            <div className="CarouselBodyItemOurParksBlock">
                              {park.name}
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  </Carousel>
                </div>
              </div>
            </div>
          ) : (
            <div className="OurParksBlock_mob">
              <div className="bodyOurParksBlock_mob">
                <div className="headerOurParksBlock_mob">
                  <div className="ParksOurParksBlock_mob">
                    <div className="Parks1OurParksBlock_mob">
                      Нашим сервисом
                    </div>
                    <div className="Parks2OurParksBlock_mob emphasized">
                      {parkCountText1} {parks.length} {parkCountText2}:
                    </div>
                  </div>
                </div>
                <div className="CarouselOurParksBlock_mob">
                  <Carousel
                    swipeable={true}
                    arrows={false}
                    responsive={responsive}
                    infinite={true}
                    autoPlay={true}
                    autoPlaySpeed={2000}
                  >
                    {parks.map((park) => {
                      return (
                        <div
                          className="CarouselFakeItemOurParksBlock_mob"
                          key={park.id}
                        >
                          <div
                            key={park.id}
                            className="CarouselItemOurParksBlock_mob"
                          >
                            <div className="CarouselBodyItemOurParksBlock_mob">
                              {park.name}
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  </Carousel>
                </div>
                <div className="footerOurParksBlock_mob">
                  <div className="CarsOurParksBlock_mob">
                    <div className="Cars1OurParksBlock_mob emphasized">
                      {carsCount} {carsCountText1}
                    </div>
                    <div className="Cars2OurParksBlock_mob">
                      {carsCountText2} к системе на данный момент
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  )
}
