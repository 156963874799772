import React from 'react';

import car_with_blue from '../icons/car_with_blue.png';
import map_with_blue from '../icons/map_with_blue.png';
import car_with_blue_mob from '../icons/car_with_blue_mob.png';
import map_with_blue_mob from '../icons/map_with_blue_mob.png';
import keys from '../icons/keys.png';
import route from '../icons/route.png';
import yellow_route from '../icons/yellow_route.png';
import cars_in_line from '../icons/cars_in_line.png';

// import fonMain from '../icons/fonMain.png';
// import fonMain from '../icons/fonMain.png';
import './style.css'

export function OverviewBlock({ size, marketRef }) {

    return (
        <>
        {size === 'BIG'
        ?
            <div className='OverviewBlock' ref={marketRef}>
                <div className='bodyOverviewBlock'>
                    <div className='titleOverviewBlock'>
                        Обзор рынка
                    </div>
                    <div className='Row1OverviewBlock'>
                        <div className='cardRow1OverviewBlock'>
                            <div className='titleCardRow1OverviewBlock'>
                                Рынок такси на <span className='emphasized'>2023 год</span>
                            </div>
                            <div className='divTextCardRow1OverviewBlock'>
                                <div className='textCardRow1OverviewBlock'>
                                    Более <span className='emphasized'>500 000</span> автомобилей
                                </div>
                                <div className='textCardRow1OverviewBlock'>
                                    Более <span className='emphasized'>4 000</span> таксопарков в одном только Яндекс.Такси
                                </div>
                            </div>
                            <img src={car_with_blue} alt='' className='imgCardRow1OverviewBlock'/>
                        </div>
                        <div className='cardRow1OverviewBlock'>
                            <div className='titleCardRow1OverviewBlock'>
                                Таксопарку важно <span className='emphasized'>следить</span> за своими авто
                            </div>
                            <div className='divTextCardRow1OverviewBlock'>
                                <div className='textCardRow1OverviewBlock'>
                                    Для этого в каждый автомобиль устанавливается <span className='emphasized'>GPS-трекер</span> с функцией глушения двигателя
                                </div>
                            </div>
                            <img src={map_with_blue} alt='' className='imgCardRow1OverviewBlock'/>
                        </div>
                    </div>
                    <div className='Row2OverviewBlock'>
                        <div className='cardRow2OverviewBlock'>
                            <div className='titleCardRow2OverviewBlock'>
                                Основа бизнес-модели таксопарков — <span className='warningLight'>лизинг</span>
                            </div>
                            <div  className='textCardRow2OverviewBlock'>
                                Таксопарки берут автомобили в лизинг и сдают машину в аренду водителю. Как только лизинг выплачивается, таксопарк продает авто и берет вместо него новый
                            </div>
                            <img src={route} alt='' className='routeCardRow2OverviewBlock'/>
                            <img src={keys} alt='' className='keysCardRow2OverviewBlock'/>
                        </div>
                    </div>
                    <div className='Row3OverviewBlock'>
                        <div className='div1Row3OverviewBlock'>
                            <div className='titleDiv1Row3OverviewBlock'>
                                <span className='emphasized'>Раз в 5 лет</span> таксопарки полностью меняют автопарк
                            </div>
                            <div className='textDiv1Row3OverviewBlock'>
                                На все эти авто требуются новые GPS-трекеры, что дает объем рынка GPS-мониторинга по России
                            </div>
                        </div>
                        <div className='div2Row3OverviewBlock'>
                            <div className='cardRow3OverviewBlock'>
                                <img src={cars_in_line} alt='' className='imgCardRow3OverviewBlock'/>
                                <div className='textCardRow3OverviewBlock'>
                                    <span className='warningLight'>100 000 GPS-трекеров</span> в год на одном только такси
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        :
            <div className='OverviewBlock_mob' ref={marketRef}>
                <div className='bodyOverviewBlock_mob'>
                    <div className='titleOverviewBlock_mob'>
                        Обзор рынка
                    </div>
                    <div className='Row1OverviewBlock_mob'>
                        <div className='cardRow1OverviewBlock_mob'>
                            <div className='titleCardRow1OverviewBlock_mob'>
                                Рынок такси на <span className='emphasized'>2023 год</span>
                            </div>
                            <div className='divTextCardRow1OverviewBlock_mob'>
                                <div className='textCardRow1OverviewBlock_mob'>
                                    Более <span className='emphasized'>500 000</span> автомобилей
                                </div>
                                <div className='textCardRow1OverviewBlock_mob'>
                                    Более <span className='emphasized'>4 000</span> таксопарков в одном только Яндекс.Такси
                                </div>
                            </div>
                            <img src={car_with_blue_mob} alt='' className='imgCardRow1OverviewBlock_mob'/>
                        </div>
                        <div className='cardRow1OverviewBlock_mob'>
                            <div className='titleCardRow1OverviewBlock_mob'>
                                Таксопарку важно <span className='emphasized'>следить</span> за своими авто
                            </div>
                            <div className='divTextCardRow1OverviewBlock_mob'>
                                <div className='textCardRow1OverviewBlock_mob'>
                                    Для этого в каждый автомобиль устанавливается <span className='emphasized'>GPS-трекер</span> с функцией глушения двигателя
                                </div>
                            </div>
                            <img src={map_with_blue_mob} alt='' className='imgCardRow1OverviewBlock_mob'/>
                        </div>
                    </div>
                    <div className='Row2OverviewBlock_mob'>
                        <div className='cardRow2OverviewBlock_mob'>
                            <div className='titleCardRow2OverviewBlock_mob'>
                                Основа бизнес-модели таксопарков — <span className='warningLight'>лизинг</span>
                            </div>
                            <div  className='textCardRow2OverviewBlock_mob'>
                                Таксопарки берут автомобили в лизинг и сдают машину в аренду водителю. Как только лизинг выплачивается, таксопарк продает авто и берет вместо него новый
                            </div>
                            <div className='fakeImgCardRow2OverviewBlock_mob'>
                            </div>
                            <img src={yellow_route} alt='' className='routeCardRow2OverviewBlock_mob'/>
                            <img src={keys} alt='' className='keysCardRow2OverviewBlock_mob'/>
                        </div>
                    </div>
                    <div className='Row3OverviewBlock_mob'>
                        <div className='titleDiv1Row3OverviewBlock_mob'>
                            <span className='emphasized'>Раз в 5 лет</span> таксопарки полностью меняют автопарк
                        </div>
                        <div className='textDiv1Row3OverviewBlock_mob'>
                            На все эти авто требуются новые GPS-трекеры, что дает объем рынка GPS-мониторинга по России
                        </div>
                        <div className='cardRow3OverviewBlock_mob'>
                            <img src={cars_in_line} alt='' className='imgCardRow3OverviewBlock_mob'/>
                            <div className='textCardRow3OverviewBlock_mob'>
                                <span className='warningLight'>100 000 GPS-трекеров</span> в год на одном только такси
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        }
        </>
    )
}