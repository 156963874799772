import React from "react";

export default function Basic_modal({active, onClose, children}){

    return (
        <div className={active ? "video_modal_mob active" : "video_modal_mob"} onClick={onClose}>
            <div className="video_modal_content_mob" onClick={e => e.stopPropagation()}>
                {children}
            </div>
        </div>
    );

}