import React, { useState, useEffect, useRef } from 'react';
import { TopBarBlock } from './TopBarBlock'
import { MainBlock } from './MainBlock'
import { OverviewBlock } from './OverviewBlock'
import { MarketBlock } from './MarketBlock'
import { ModelBlock } from './ModelBlock'
import { EconomyBlock } from './EconomyBlock'
import { HowMakeMoney } from './HowMakeMoney'
import { OfferBlock } from './OfferBlock'
import { PutBlock } from './PutBlock'
import { BottomBarBlock } from './BottomBarBlock'

import './style.css'

export function Franchise() {

    const [size, setSize] = useState('BIG')
    const [sizeValue, setSizeValue] = useState(document.documentElement.clientWidth)

    const marketRef = useRef(null)
    const modelRef = useRef(null)
    const economyRef = useRef(null)
    const earningsRef = useRef(null)
    const offerRef = useRef(null)
    const putRef = useRef(null)


    const onresize = () => {
        if (document.documentElement.clientWidth > 1024){
            setSize('BIG')
        } else {
            setSize('SML')
        }
        setSizeValue(document.documentElement.clientWidth)
    }

    window.onresize = onresize

    useEffect(() => {
        onresize()
    }, []);
    
    const toMarket = () => {
        marketRef.current.scrollIntoView({
            behavior: "smooth",
        });
    }

    const toModel = () => {
        modelRef.current.scrollIntoView({
            behavior: "smooth",
        });
    }

    const toEconomy = () => {
        economyRef.current.scrollIntoView({
            behavior: "smooth",
        });
    }

    const toEarnings = () => {
        earningsRef.current.scrollIntoView({
            behavior: "smooth",
        });
    }

    // const toHowMakeMoney = () => {
    //     howMakeMoneyRef.current.scrollIntoView({
    //         behavior: "smooth",
    //     });
    // }

    const toOffer = () => {
        offerRef.current.scrollIntoView({
            behavior: "smooth",
        });
    }

    const toPut = () => {
        putRef.current.scrollIntoView({
            behavior: "smooth",
        });
    }

    return (
        <div className='franchise'>
            <TopBarBlock 
                size={size}
                sizeValue={sizeValue}
                toMarket={toMarket} 
                toModel={toModel} 
                toEconomy={toEconomy} 
                toEarnings={toEarnings}
                toOffer={toOffer} 
                toPut={toPut}
            />
            <MainBlock size={size} toPut={toPut} />
            <OverviewBlock size={size} marketRef={marketRef} />
            <MarketBlock size={size} />
            <ModelBlock size={size} modelRef={modelRef}/>
            <EconomyBlock size={size} economyRef={economyRef} earningsRef={earningsRef}/>
            <HowMakeMoney size={size} offerRef={offerRef} />
            <OfferBlock size={size} />
            <PutBlock size={size} putRef={putRef} />
            <BottomBarBlock size={size} />
        </div>
    )
}