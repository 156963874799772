import React from 'react';
import dot_for_money from '../icons/dot_for_money.svg';
import group1_for_money from '../icons/group1_for_money.svg';
import group2_for_money from '../icons/group2_for_money.svg';
import group_for_money_mob from '../icons/group_for_money_mob.svg';
import dot1_for_money from '../icons/dot1_for_money.svg';
import dot2_for_money from '../icons/dot2_for_money.svg';
import dot3_for_money from '../icons/dot3_for_money.svg';
import dot4_for_money from '../icons/dot4_for_money.svg';
import dot5_for_money from '../icons/dot5_for_money.svg';
import dot6_for_money from '../icons/dot6_for_money.svg';
import './style.css'

export function HowMakeMoney({ size, offerRef }) {

    return (
        <>
        {size === 'BIG'
        ?
            <div className='HowMakeMoney' ref={offerRef}>
                <div className='bodyHowMakeMoney'>
                    <div className='titleHowMakeMoney'>
                        Как начать зарабатывать?
                    </div>
                    <div className='rowHowMakeMoney'>
                        <div className='bandHowMakeMoney'>
                        </div>
                        <div className='itemHowMakeMoney'>
                            <div className='numberItemHowMakeMoney'>
                                01.
                            </div>
                            <div className='iconDivHowMakeMoney'>
                                <img src={dot_for_money} alt='' className='iconHowMakeMoney'/>
                            </div>
                            <div className='textItemHowMakeMoney'>
                                Оставьте свои контакты 
                            </div>
                        </div>
                        <div className='itemHowMakeMoney'>
                            <div className='numberItemHowMakeMoney'>
                                02.
                            </div>
                            <div className='iconDivHowMakeMoney'>
                                <img src={dot_for_money} alt='' className='iconHowMakeMoney'/>
                            </div>
                            <div className='textItemHowMakeMoney'>
                                Пройдите обучение
                            </div>
                        </div>
                        <div className='itemHowMakeMoney'>
                            <div className='numberItemHowMakeMoney'>
                                03.
                            </div>
                            <div className='iconDivHowMakeMoney'>
                                <img src={dot_for_money} alt='' className='iconHowMakeMoney'/>
                            </div>
                            <div className='textItemHowMakeMoney'>
                                Наймите менеджера по продажам
                            </div>
                        </div>
                        <div className='itemHowMakeMoney'>
                            <div className='numberItemHowMakeMoney'>
                                04.
                            </div>
                            <div className='iconDivHowMakeMoney'>
                                <img src={dot_for_money} alt='' className='iconHowMakeMoney'/>
                            </div>
                            <div className='textItemHowMakeMoney'>
                                Закажите оборудование
                            </div>
                        </div>
                        <div className='itemHowMakeMoney'>
                            <div className='numberItemHowMakeMoney'>
                                05.
                            </div>
                            <div className='iconDivHowMakeMoney'>
                                <img src={dot_for_money} alt='' className='iconHowMakeMoney'/>
                            </div>
                            <div className='textItemHowMakeMoney'>
                                Наймите автоэлектрика
                            </div>
                        </div>
                        <div className='itemHowMakeMoney'>
                            <div className='numberItemHowMakeMoney'>
                                06.
                            </div>
                            <div className='iconDivHowMakeMoney'>
                                <img src={dot_for_money} alt='' className='iconHowMakeMoney'/>
                            </div>
                            <div className='textItemHowMakeMoney'>
                                Продавайте клиентам оборудование
                            </div>
                        </div>
                    </div>
                    <img src={group1_for_money} alt='' className='group1HowMakeMoney'/>
                    <img src={group2_for_money} alt='' className='group2HowMakeMoney'/>
                </div>
            </div>
        :
            <div className='HowMakeMoney_mob' ref={offerRef}>
                <div className='bodyHowMakeMoney_mob'>
                    <img src={group_for_money_mob} alt='' className='iconHowMakeMoney_mob'/>
                    <div className='titleHowMakeMoney_mob'>
                        Как начать зарабатывать?
                    </div>
                    <div className='columnHowMakeMoney_mob'>
                        <div className='itemHowMakeMoney_mob'>
                            <img src={dot1_for_money} alt='' className='iconDotHowMakeMoney_mob'/>
                            <div className='textItemHowMakeMoney_mob'>
                                Оставьте свои контакты 
                            </div>
                        </div>
                        <div className='bandHowMakeMoney_mob'>
                        </div>
                        <div className='itemHowMakeMoney_mob'>
                            <img src={dot2_for_money} alt='' className='iconDotHowMakeMoney_mob'/>
                            <div className='textItemHowMakeMoney_mob'>
                                Пройдите обучение
                            </div>
                        </div>
                        <div className='bandHowMakeMoney_mob'>
                        </div>
                        <div className='itemHowMakeMoney_mob'>
                            <img src={dot3_for_money} alt='' className='iconDotHowMakeMoney_mob'/>
                            <div className='textItemHowMakeMoney_mob'>
                                Наймите менеджера по продажам
                            </div>
                        </div>
                        <div className='bandHowMakeMoney_mob'>
                        </div>
                        <div className='itemHowMakeMoney_mob'>
                            <img src={dot4_for_money} alt='' className='iconDotHowMakeMoney_mob'/>
                            <div className='textItemHowMakeMoney_mob'>
                                Закажите оборудование
                            </div>
                        </div>
                        <div className='bandHowMakeMoney_mob'>
                        </div>
                        <div className='itemHowMakeMoney_mob'>
                            <img src={dot5_for_money} alt='' className='iconDotHowMakeMoney_mob'/>
                            <div className='textItemHowMakeMoney_mob'>
                                Наймите автоэлектрика
                            </div>
                        </div>
                        <div className='bandHowMakeMoney_mob'>
                        </div>
                        <div className='itemHowMakeMoney_mob'>
                            <img src={dot6_for_money} alt='' className='iconDotHowMakeMoney_mob'/>
                            <div className='textItemHowMakeMoney_mob'>
                                Продавайте клиентам оборудование
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        }
        </>
    )
}