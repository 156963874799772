import React from 'react';

import BtnDefolt from '../../../components/UI/btn_defolt.jsx'

import fonMain from '../icons/fonMain.png';
import pcMain from '../icons/pcMain.png';
import pcMain_mob from '../icons/pcMain_mob.png';
import phoneIconBlue from '../icons/phoneIconBlue.svg';
// import phoneIconBlue_mob from '../icons/phoneIconBlue_mob.svg';

import './style.css'

export function MainBlock({ size, toPut }) {

    return (
        <>
        {size === 'BIG'
        ?
            <div className='MainBlock'>
                <img src={fonMain} alt='' className='fonMainBlock'/>
                <img src={pcMain} alt='' className='pcMainBlock'/>
                
                <div className='cardMainBlock'>
                    <div className='ControlGPSMainBlock emphasized'>
                        ControlGPS
                    </div>
                    <div className='textMainBlock'>
                        <span className='emphasized'>Станьте партнером инновационной системы</span> мониторинга за автопарком, и зарабатывайте вместе с нами от <span className='emphasized'>500 000 ₽</span> в месяц!
                    </div>
                    <div className='btnsMainBlock'>
                        <div className='putBtnMainBlock'>
                            <BtnDefolt color={'#6486FB'} text_color={'#FFFFFF'} hover_color={'#5179fc'} text={'Оставить заявку'} height={'45px'} width={'200px'} fontSize={'18px'} onClick={toPut}/>
                        </div>
                        <div className='phoneBtnMainBlock'>
                            <img src={phoneIconBlue} alt='' className='phoneIconMainBlock'/>
                            <a href="tel:+7(495)215-07-15" className='telNavbarMainBlock emphasized'>
                                +7 (495) 215-07-15
                            </a>
                        </div>
                    </div>
                </div>
                <div className='card2MainBlock'>

                </div>
                <div className='card3MainBlock'>

                </div>
            </div>
        :
            <div className='MainBlock_mob'>
                <img src={fonMain} alt='' className='fonMainBlock_mob'/>
                
                <div className='cardMainBlock_mob'>
                    <div className='ControlGPSMainBlock_mob emphasized'>
                        ControlGPS
                    </div>
                    <div className='textMainBlock_mob'>
                        <span className='emphasized'>Станьте партнером инновационной системы</span> мониторинга за автопарком, и зарабатывайте вместе с нами от <span className='emphasized'>500 000 ₽</span> в месяц!
                    </div>
                    <div className='btnsMainBlock_mob'>
                        <div className='putBtnMainBlock_mob'>
                            <BtnDefolt color={'#6486FB'} text_color={'#FFFFFF'} hover_color={'#5179fc'} text={'Оставить заявку'} height={'40px'} width={'135px'} fontSize={'12px'} onClick={toPut}/>
                        </div>
                        <div className='phoneBtnMainBlock_mob'>
                            <a href="tel:+7(495)215-07-15" className='telNavbarMainBlock_mob emphasized'>
                                +7 (495) 215-07-15
                            </a>
                        </div>
                    </div>
                </div>
                <div className='card2MainBlock_mob'>
                </div>
                <div className='card3MainBlock_mob'>
                </div>

                <div className='divPcMainBlock_mob'>
                    <img src={pcMain_mob} alt='' className='pcMainBlock_mob'/>
                </div>
            </div>
        }
        </>
    )
}