import React from 'react';
import logo_footer from '../../../styles/icons/logo_footer.svg';
import logo_footer_mob from '../../../styles/icons/logo_footer_mob.svg';
import phone_footer from '../../../styles/icons/phone_footer.svg';
import phone_footer_mob from '../../../styles/icons/phone_footer_mob.svg';

import './style.css'

export function BottomBarBlock({size}) {

    const go_privacy = () => {
        if (process.env.REACT_APP_SERVER == 'PROD') {
            window.location.href = "http://crm.controlgps.org/privacy_policy";
        } else {
            window.location.href = "http://dev.crm.controlgps.org/privacy_policy";
        }
    }

    return (
        <>
        {size === 'BIG'
        ?
            <div className='BottomBarBlockFranchise'>
                <div className='bodyBottomBarBlockFranchise'>
                    <div>
                        <div className='otdelBottomBarBlockFranchise'>
                            Отдел продаж:
                        </div>
                        <div className='phoneBottomBarBlockFranchise'>
                            <img src={phone_footer} alt="" />
                            <a href="tel:+7(495)215-07-15" className='phoneABottomBarBlockFranchise'>
                                +7 (495) 215-07-15
                            </a>
                        </div>
                        <div className='periodBottomBarBlockFranchise'>
                            Часы работы: 9:00 - 18:00 МСК
                        </div>
                    </div>
                    <div style={{ marginRight: '32px' }}>
                        <div className='logoBottomBarBlockFranchise'>
                            <img src={logo_footer} alt=''/>
                        </div>
                        <div className='politBottomBarBlockFranchise'>
                            Политика конфиденциальности
                        </div>
                        <div className='ofBottomBarBlockFranchise'>
                            © 2022, Официальный сайт «Control GPS»
                        </div>
                    </div>
                </div>
            </div> 
        :
            <div className='BottomBarBlockFranchise_mob'>
                <div className='bodyBottomBarBlockFranchise_mob'>
                    <div className='logoBottomBarBlockFranchise_mob'>
                        <img src={logo_footer_mob} alt=''/>
                    </div>
                    <div className='otdelBottomBarBlockFranchise_mob'>
                        Отдел продаж:
                    </div>
                    <div className='phoneBottomBarBlockFranchise_mob'>
                        <img src={phone_footer_mob} alt="" height='20px' width='20px'/>
                        <a href="tel:+7(495)215-07-15" className='phoneABottomBarBlock'>
                            +7 (495) 215-07-15
                        </a>
                    </div>
                    <div className='periodBottomBarBlockFranchise_mob'>
                        Часы работы: 9:00 - 18:00 МСК
                    </div>
                    <div className='politBottomBarBlockFranchise_mob' onClick={go_privacy}>
                        Политика конфиденциальности
                    </div>
                    <div className='ofBottomBarBlockFranchise_mob'>
                        © 2022, Официальный сайт «Control GPS»
                    </div>
                </div>          
            </div>
        }
        </>
    )
}