import React, { useState, useEffect, useRef } from 'react'
import { TopBarBlock } from './TopBarBlock'
import { ControlGpsAndVideoBlock } from './ControlGpsAndVideoBlock'
import { ExperienceBlock } from './ExperienceBlock'
import { OurDevelopmentBlock } from './OurDevelopmentBlock'
import { EquipmentBlock } from './EquipmentBlock'
import { BenefitBlock } from './BenefitBlock'
import { PutBlock } from './PutBlock'
import { BottomBarBlock } from './BottomBarBlock'
import { OurParksBlock } from './OurParksBlock'
import './style.css'

export function Landing() {
  const [size, setSize] = useState('BIG')

  const whyWeRef = useRef(null)
  const advantagesRef = useRef(null)
  const benefitsRef = useRef(null)
  const putRef = useRef(null)

  const onresize = () => {
    if (document.documentElement.clientWidth > 1024) {
      setSize('BIG')
    } else {
      setSize('SML')
    }
  }

  window.onresize = onresize

  useEffect(() => {
    onresize()
  }, [])

  const whyWe = () => {
    whyWeRef.current.scrollIntoView({
      behavior: 'smooth',
    })
  }

  const tryFree = () => {
    putRef.current.scrollIntoView({
      behavior: 'smooth',
    })
  }

  const tryDemo = () => {
    if (process.env.REACT_APP_SERVER == 'PROD') {
      window.open('http://crm.controlgps.org/auth?demo=demo')
    } else {
      window.open('http://dev.crm.controlgps.org/auth?demo=demo')
    }
  }

  const advantages = () => {
    advantagesRef.current.scrollIntoView({
      behavior: 'smooth',
    })
  }

  const benefits = () => {
    benefitsRef.current.scrollIntoView({
      behavior: 'smooth',
    })
  }

  return (
    <div className='landing'>
      <TopBarBlock size={size} whyWe={whyWe} advantages={advantages} benefits={benefits} />
      <ControlGpsAndVideoBlock tryFree={tryFree} tryDemo={tryDemo} size={size} />
      <ExperienceBlock size={size} whyWeRef={whyWeRef} />
      <OurParksBlock size={size} />
      <OurDevelopmentBlock size={size} tryFree={tryFree} advantagesRef={advantagesRef} />
      <EquipmentBlock size={size} tryFree={tryFree} />
      <BenefitBlock size={size} tryFree={tryFree} benefitsRef={benefitsRef} />
      <PutBlock size={size} putRef={putRef} />
      <BottomBarBlock size={size} />
    </div>
  )
}
