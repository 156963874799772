 import React, {useState} from 'react';

import ClickAwayListener from '@mui/material/ClickAwayListener';
import { Transition } from 'react-transition-group';

import BtnDefolt from '../../../components/UI/btn_defolt.jsx'

import logo from '../icons/logo.svg';
import logo_mob from '../icons/logo_mob.svg';
import phoneIcon from '../icons/phoneIcon.svg';
import menu_mob from '../../../styles/icons/menu_mob.svg';

import './style.css'

export function TopBarBlock({size, sizeValue, toMarket, toModel, toEconomy, toEarnings, toOffer, toPut}) {

    const [openMenu, setOpenMenu] = useState(false);

    const duration = 100;

    const defaultStyle = {
        transition: `opacity ${duration}ms ease-in-out`,
        opacity: 0,
        display: 'none'
    }

    const transitionStyles = {
        entering: { opacity: 1, display: 'block' },
        entered:  { opacity: 1, display: 'block' },
        exiting:  { opacity: 0, display: 'block' },
        exited:  { opacity: 0, display: 'none' },
    };

    return (
        <>
        {size === 'BIG'
        ?
            <div className='TopBarBlockFranchise'>
                <div className='navbarFranchise'>
                    <img src={logo} alt='' className='logoNavbarFranchise'/>
                    <div className='textNavbarFranchise' onClick={toMarket}>
                        Рынок
                    </div>
                    <div className='textNavbarFranchise' onClick={toModel}>
                        Бизнес-модель
                    </div>
                    <div className='textNavbarFranchise' onClick={toEconomy}>
                        Экономика
                    </div>
                    <div className='textNavbarFranchise' onClick={toEarnings}>
                        Заработок
                    </div>
                    <div className='textNavbarFranchise' onClick={toOffer}>
                        Наше предложение
                    </div>
                    
                    <div className='phoneAndLoginFranchise'>
                        {sizeValue >= 1224
                            ?
                                <div className='phoneNavbarFranchise'>
                                    <img src={phoneIcon} alt='' className='phoneIconNavbarFranchise'/>
                                    <a href="tel:+7(495)215-07-15" className='telNavbarFranchise'>
                                        +7 (495) 215-07-15
                                    </a>
                                </div>
                            : 
                                <></> 
                        }
                        <div className='loginNavbarFranchise'>
                            <BtnDefolt color={'#6486FB'} text_color={'#FFFFFF'} hover_color={'#5179fc'} text={'Оставить заявку'} height={'30px'} width={'133px'} fontSize={'12px'} onClick={toPut}/>
                        </div>
                    </div>
                </div>                                     
            </div> 
        :
            <div className='TopBarBlockFranchise'>
                <div className='navbarFranchise_mob'>
                    <img src={logo_mob} alt='' className='logoNavbarFranchise_mob' />
                    <div className='phoneNavbarFranchise_mob'>
                        <BtnDefolt color={'#6486FB'} text_color={'#FFFFFF'} hover_color={'#5179fc'} text={'Оставить заявку'} height={'23px'} width={'105px'} fontSize={'10px'} onClick={toPut}/>
                    </div>
                    <div className='menuNavbarFranchise'>
                        <ClickAwayListener onClickAway={() => setOpenMenu(false)}>
                            <div className='iconAndBodyMenuNavbarFranchise'>
                                <div className='divIconMenuNavbarFranchise' onClick={() => setOpenMenu(!openMenu)}>
                                    <img src={menu_mob} className='iconMenuNavbarFranchise' alt=''/>
                                </div>
                                <Transition in={openMenu} timeout={duration}>
                                    {state => (
                                        <div style={{
                                        ...defaultStyle,
                                        ...transitionStyles[state]
                                        }} className="menuBodyNavbarFranchise">
                                            <div className='textMenuBodyNavbarFranchise' onClick={toMarket}>
                                                Рынок
                                            </div>
                                            <div className='textMenuBodyNavbarFranchise' onClick={toModel}>
                                                Бизнес-модель
                                            </div>
                                            <div className='textMenuBodyNavbarFranchise' onClick={toEconomy}>
                                                Экономика
                                            </div>
                                            <div className='textMenuBodyNavbarFranchise' onClick={toEarnings}>
                                                Заработок
                                            </div>
                                            <div className='textMenuBodyNavbarFranchise' onClick={toOffer}>
                                                Наше предложение
                                            </div>
                                            <div className='textMenuBodyNavbarFranchise emphasized' onClick={toPut}>
                                                Оставить заявку
                                            </div>
                                        </div>  
                                    )}
                                </Transition>     
                            </div>
                        </ClickAwayListener>
                    </div>
                </div>                                     
            </div>
        }
        </>
    )
}