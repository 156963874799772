import React, { useState, useEffect } from 'react'
import BtnDefolt from '../../../components/UI/btn_defolt.jsx'
import BtnDefoltIcon from '../../../components/UI/btn_defolt_icon.jsx'
import BtnDefolt2 from '../../../components/UI/btn_defolt2.jsx'
import BasicModal from './VideoModal.jsx'
import BasicModalMob from './VideoModalMob.jsx'
import ReactPlayer from 'react-player'
import playcircle from '../../../styles/icons/playcircle.svg'

import './style.css'

const videoUrl = 'https://www.youtube.com/watch?v=KDpB5Mu6XVg'
const shortVideoUrl = 'https://youtube.com/shorts/qu1hyHNAfiQ?feature=share'

export function ControlGpsAndVideoBlock({ size, tryFree, tryDemo }) {
  const [load, setLoad] = useState(true)

  const [playVideo, setPlayVideo] = useState(false)
  const [playVideoModal, setPlayVideoModal] = useState(false)
  const [modalVideoActive, setModalVideoActive] = useState(false)

  const [playVideoMob, setPlayVideoMob] = useState(false)
  const [playVideoModalMob, setPlayVideoModalMob] = useState(false)
  const [modalVideoActiveMob, setModalVideoActiveMob] = useState(false)

  useEffect(() => {
    window.addEventListener('load', () => {
      setLoad(true)
    })
  }, [])

  const onReadyVideo = () => {
    setPlayVideo(true)
  }

  const openVideo = () => {
    setModalVideoActive(true)
    setPlayVideoModal(true)
    setPlayVideo(false)
  }

  const onClose = () => {
    setModalVideoActive(false)
    setPlayVideoModal(false)
    setPlayVideo(true)
  }

  const onReadyVideoMob = () => {
    setPlayVideoMob(true)
  }

  const openVideoMob = () => {
    setModalVideoActiveMob(true)
    setPlayVideoModalMob(true)
    setPlayVideoMob(false)
  }

  const onCloseMob = () => {
    setModalVideoActiveMob(false)
    setPlayVideoModalMob(false)
    setPlayVideoMob(true)
  }

  return (
    <>
      {size === 'BIG' ? (
        <div className='ControlGpsAndVideoBlock'>
          <div className='descriptionBlock'>
            <div className='_1_descriptionBlock'>
              <span className='emphasized'>ControlGPS</span> — система мониторинга автопарка
            </div>
            <div className='_2_descriptionBlock'>
              Вы можете подключить к нам{' '}
              <span className='emphasized'>уже имеющееся оборудование</span> и начать экономить до
              75% c первого дня пользования системой!
            </div>
            <div className='buttonsDescriptionBlock'>
              <div>
                <BtnDefolt
                  color={'#6486FB'}
                  text_color={'#FFFFFF'}
                  hover_color={'#5179fc'}
                  text={'Попробовать бесплатно'}
                  height={'50px'}
                  width={'250px'}
                  onClick={tryFree}
                />
                <div className='muted periodDescriptionBlock'>*Пробный период длится 7 дней</div>
              </div>
              <div>
                <BtnDefolt
                  color={'#dee3ee'}
                  text_color={'#3C4A59'}
                  hover_color={'#d1d6e0'}
                  text={'Демо вход'}
                  height={'50px'}
                  width={'210px'}
                  onClick={tryDemo}
                />
              </div>
            </div>
            <div></div>
          </div>
          <div className='videoBlock'>
            {load ? (
              <>
                <div className='ButtonsVideoBlock'>
                  <div className='BtnVideoBlock'>
                    <BtnDefoltIcon
                      icon={playcircle}
                      color={'#dee3ee'}
                      text_color={'#FFFFFF'}
                      hover_color={'#d1d6e0'}
                      text={'Смотреть видео'}
                      height={'40px'}
                      width={'170px'}
                      onClick={openVideo}
                    />
                  </div>
                </div>
                <div className='playerVideoBlock'>
                  <ReactPlayer
                    url={videoUrl}
                    config={{
                      youtube: {
                        playerVars: {
                          showinfo: 0,
                          controls: 0,
                          autohide: 1,
                        },
                      },
                    }}
                    loop={true}
                    muted={true}
                    volume={0}
                    onReady={onReadyVideo}
                    playing={playVideo}
                    height={'740px'}
                    width={'1315px'}
                  />
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
          <div>
            <BasicModal active={modalVideoActive} onClose={onClose}>
              {modalVideoActive ? (
                <div style={{ position: 'absolute', top: '0px' }}>
                  <ReactPlayer
                    url={videoUrl}
                    controls={true}
                    loop={true}
                    volume={20}
                    playing={playVideoModal}
                    height={'540px'}
                    width={'960px'}
                  />
                </div>
              ) : (
                <></>
              )}
            </BasicModal>
          </div>
        </div>
      ) : (
        <div className='ControlGpsAndVideoBlock_mob'>
          <div className='bodyControlGpsAndVideoBlock_mob'>
            <div className='descriptionBlock_mob'>
              <div className='_1_descriptionBlock_mob'>
                <span className='emphasized'>ControlGPS</span> — система мониторинга автопарка
              </div>
              <div className='_2_descriptionBlock_mob'>
                Вы можете подключить к нам{' '}
                <span className='emphasized'>уже имеющееся оборудование</span> и начать экономить до
                75% c первого дня пользования системой!
              </div>
              <div className='buttonsDescriptionBlock_mob'>
                <div>
                  <BtnDefolt2
                    color={'#6486FB'}
                    text_color={'#FFFFFF'}
                    hover_color={'#5179fc'}
                    text={'Попробовать бесплатно '}
                    height={'40px'}
                    width={'200px'}
                    onClick={tryFree}
                  />
                  <div className='muted periodDescriptionBlock_mob'>
                    *Пробный период длится 7 дней
                  </div>
                </div>
                <div>
                  <BtnDefolt2
                    color={'#dee3ee'}
                    text_color={'#3C4A59'}
                    hover_color={'#d1d6e0'}
                    text={'Демо вход'}
                    height={'40px'}
                    width={'100px'}
                    onClick={tryDemo}
                  />
                </div>
              </div>
              <div></div>
            </div>
            <div className='videoBlock_mob'>
              {load ? (
                <>
                  <div className='ButtonsVideoBlock_mob'>
                    <div className='BtnVideoBlock_mob'>
                      <BtnDefoltIcon
                        icon={playcircle}
                        color={'#dee3ee'}
                        text_color={'#FFFFFF'}
                        hover_color={'#d1d6e0'}
                        text={'Смотреть видео'}
                        height={'40px'}
                        width={'170px'}
                        onClick={openVideoMob}
                      />
                    </div>
                  </div>
                  <div className='video_mob'>
                    <ReactPlayer
                      url={shortVideoUrl}
                      loop={true}
                      muted={true}
                      onReady={onReadyVideoMob}
                      playing={playVideoMob}
                      playsInline={true}
                      height={'calc(100vw - 70px)'}
                      width={'calc(100vw - 70px)'}
                      config={{
                        youtube: {
                          playerVars: {
                            showinfo: 0,
                            controls: 0,
                            autohide: 1,
                          },
                        },
                      }}
                      controls={false}
                    />
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
            <div>
              <BasicModalMob active={modalVideoActiveMob} onClose={onCloseMob}>
                {modalVideoActiveMob ? (
                  <div style={{ position: 'absolute', top: '0px' }}>
                    <ReactPlayer
                      url={shortVideoUrl}
                      controls={true}
                      loop={true}
                      volume={20}
                      playing={playVideoModalMob}
                      height={'calc(100vw - 70px)'}
                      width={'calc(100vw - 70px)'}
                    />
                  </div>
                ) : (
                  <></>
                )}
              </BasicModalMob>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
